import PropTypes from 'prop-types';
import { Document } from '@react-pdf/renderer';
import PDFSubmissionPage from './PDFSubmissionPage';
import PDFFeedbackPage from './PDFFeedbackPage';
import PDFReflectionPage from './PDFReflectionPage';

const ExportableFeedback = ({ userAssignment, assignment }) => {
  return (
    <Document>
      <PDFSubmissionPage
        userAssignment={userAssignment}
        assignment={assignment}
      />
      <PDFFeedbackPage
        userAssignment={userAssignment}
        assignment={assignment}
      />
      <PDFReflectionPage
        userAssignment={userAssignment}
        assignment={assignment}
      />
    </Document>
  );
};

ExportableFeedback.defaultProps = {
  userAssignment: {},
  assignment: {},
};

ExportableFeedback.propTypes = {
  userAssignment: PropTypes.object,
  assignment: PropTypes.object,
};

export default ExportableFeedback;
