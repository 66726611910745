import PropTypes from 'prop-types';
import { pluralizeWithCount } from '@gonurture/design-system';

const GradedRubricsTable = ({ showPoints, userAssignment, assignment }) => {
  const rubrics = assignment?.rubrics_json || [];
  const feedback = userAssignment?.submissions?.[0]?.feedback || {};

  const canShowPoints =
    showPoints && assignment?.rubrics_grade_display === 'points';

  const maxColumnPoint = Math.max(
    ...(rubrics?.[0]?.columns || []).map((column) => column.point),
  );

  const isCellGraded = (cellId) => {
    if (!canShowPoints) return false;

    return feedback?.rubric_grades?.some(
      (grade) => grade.rubric_cell_id === cellId,
    );
  };

  return (
    <div className='rounded-lg border border-[#EAECF0] shadow-sm'>
      <div className='px-3 py-6'>
        {assignment?.rubrics_grade_display === 'points' && (
          <>
            <div className='font-semibold text-[#101828] text-lg mb-1'>
              {canShowPoints ? (
                <>Rubric Points Available : {assignment?.max_points}</>
              ) : (
                <>Rubrics</>
              )}
            </div>
            <div className='text-[#475467] text-sm'>
              Points are split evenly across all learning outcomes
            </div>
          </>
        )}

        {assignment?.rubrics_grade_display === 'labels' && (
          <div className='font-semibold text-[#101828] text-lg mb-2'>
            Rubrics is graded with Labels
          </div>
        )}
      </div>

      {rubrics.length > 0 && (
        <div className='overflow-x-auto'>
          <table className='min-w-full bg-white border'>
            <thead>
              <tr className='bg-[#F4F3FF] text-left border-b text-xs'>
                <th className='p-3 border-x min-w-[200px]'>Learning Outcome</th>
                {rubrics[0].columns.map((column) => (
                  <th className='min-w-[200px] p-3 border-x' key={column.id}>
                    <div className='flex justify-between align-center'>
                      <span>{column.name}</span>
                      {canShowPoints && (
                        <span className='text-[#7F56D9]'>
                          {pluralizeWithCount('point', column.point)}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {rubrics.map((rubric, index) => {
                return (
                  <tr key={index}>
                    <td className='border border-l-0'>
                      <div className='p-3 flex'>
                        <div className='shrink-0 font-semibold'>
                          {index + 1}.
                        </div>
                        <div className='flex-grow ml-1 text-sm font-semibold'>
                          {rubric['objective_name']}
                        </div>
                      </div>
                    </td>
                    {rubric.columns.map((column, index) => (
                      <td
                        className={`p-3 border text-sm ${isCellGraded(column.cell_id) && 'bg-[#F4EBFF]'}`}
                        key={index}
                      >
                        <div className='mb-2'>{column.value}</div>

                        {isCellGraded(column.cell_id) && (
                          <div className='flex'>
                            <div className='text-[#7341C6] text-xs font-medium border border-[#E9D7FE] bg-[#F9F5FF] rounded-xl px-2 py-1'>
                              {column.name}{' '}
                              {canShowPoints &&
                                `(${column.point}/${maxColumnPoint})`}
                            </div>
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

GradedRubricsTable.defaultProps = {
  showPoints: false,
  userAssignment: {},
  assignment: {},
};

GradedRubricsTable.propTypes = {
  showPoints: PropTypes.bool,
  userAssignment: PropTypes.object,
  assignment: PropTypes.object,
};

export default GradedRubricsTable;
