import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import CustomPage from './CustomPage';
import PropTypes from 'prop-types';
import { epochToDate, format, pluralize } from '@gonurture/design-system';
import InterFont from './fonts/Inter-VariableFont_opsz,wght.ttf';
import InterFontBold from './fonts/Inter_18pt-Bold.ttf';

const PDFFeedbackPage = ({ userAssignment, assignment }) => {
  const submission = userAssignment?.submissions?.[0];
  const feedback = submission?.feedback;
  const studentFirstName = userAssignment?.user?.display_name.split(' ')[0];

  const objectiveNeedsFeedback = (objective) => {
    if (assignment?.grade_display === 'grade_display_rubrics') return false;

    return feedback?.objectives?.includes(objective.id);
  };

  const objectiveRubricGrade = (objective) => {
    if (assignment?.grade_display !== 'grade_display_rubrics') {
      return null;
    }

    const rubricGrade = feedback?.rubric_grades?.find(
      (rubricGrade) => rubricGrade.objective_id === objective.id,
    );

    const columnId = rubricGrade?.rubric_column_id;

    const column = assignment?.rubrics_json?.[0]?.columns?.find(
      (column) => column.id === columnId,
    );

    const columnName = column?.name;
    const columnPoints = column?.point;

    const maxColumnPoints = Math.max(
      ...(assignment?.rubrics_json?.[0]?.columns?.map(
        (column) => column.point,
      ) || [0]),
    );

    let result = columnName;

    if (!result) {
      // Mostly ungraded
      return '-';
    }

    if (assignment?.rubrics_grade_display === 'points') {
      result = `${result} (${columnPoints}/${maxColumnPoints} ${pluralize(
        'point',
        columnPoints,
      )})`;
    }

    return result;
  };

  Font.register({
    family: 'Inter',
    fonts: [
      { src: InterFont, fontWeight: 'normal' },
      { src: InterFontBold, fontWeight: 'bold' },
    ],
  });

  const styles = StyleSheet.create({
    section: {
      marginBottom: 30,
    },
    heading: {
      fontSize: '12px',
      fontWeight: 'normal',
      marginBottom: 5,
      color: '#283E59',
    },
    normalText: {
      fontSize: '10px',
      fontWeight: 'normal',
      color: '#283E59',
    },
    bulletList: {
      marginLeft: 10,
      marginTop: 10,
    },
    bulletItem: {
      flexDirection: 'row',
      marginBottom: 3,
    },
    bulletPoint: {
      width: 10,
    },
    note: {
      marginTop: 10,
    },
    confidence: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    emoji: {
      fontSize: 14,
      fontFamily: 'NotoEmoji',
    },
    boldText: {
      fontWeight: 'bold',
    },
  });

  const SubHeader = () => (
    <>
      {feedback?.published_at && (
        <>
          Sent to student at{' '}
          {format(
            epochToDate(feedback?.published_at),
            'HH:mm [on] dddd MMMM DD YYYY',
          )}
        </>
      )}
      {['feedback_started', 'feedback_complete'].includes(
        userAssignment?.feedback_status,
      ) && (
        <Text style={{ color: '#D92D20' }}>
          Teacher feedback started but not yet sent to student!
        </Text>
      )}

      {userAssignment?.feedback_status === 'not_started' && (
        <Text style={{ color: '#D92D20' }}>
          Teacher has not yet started feedback!
        </Text>
      )}
    </>
  );

  return (
    <CustomPage
      userAssignment={userAssignment}
      assignment={assignment}
      headerText='teacher feedback for:'
      subHeader={<SubHeader />}
    >
      <View style={styles.section}>
        <Text style={styles.heading}>Personal Feedback Comment</Text>
        {feedback?.comment && (
          <Text style={styles.normalText}>{feedback.comment}.</Text>
        )}

        {!feedback?.comment && (
          <Text style={styles.normalText}>
            Teacher did not leave any personal comment for {studentFirstName}.
          </Text>
        )}
      </View>

      {/* Learning Outcomes Section */}
      <View style={styles.section}>
        <Text style={styles.heading}>Learning Outcomes</Text>
        {assignment?.objectives?.map((objective, index) => (
          <View key={index} style={{ marginBottom: '20px' }}>
            <Text style={{ ...styles.normalText, marginBottom: '10px' }}>
              {index + 1}. {objective.name}
            </Text>

            {assignment?.grade_display !== 'grade_display_rubrics' && (
              <Text style={{ ...styles.normalText }}>
                {objectiveNeedsFeedback(objective) ? (
                  <Text style={{ fontWeight: 'bold', color: '#D92D20' }}>
                    Needs Work
                  </Text>
                ) : (
                  <Text style={{ fontWeight: 'bold', color: '#0E9384' }}>
                    Success
                  </Text>
                )}
              </Text>
            )}

            {assignment?.grade_display === 'grade_display_rubrics' && (
              <Text
                style={{
                  ...styles.normalText,
                  fontWeight: 'bold',
                  color: '#7F56D9',
                }}
              >
                {objectiveRubricGrade(objective)}
              </Text>
            )}
          </View>
        ))}
      </View>

      {/* Grade Section */}
      <View style={styles.section}>
        <Text style={styles.heading}>Grade</Text>
        {assignment?.grade_display === 'grade_display_ungraded' && (
          <Text style={styles.normalText}>This is a non-graded task</Text>
        )}

        {assignment?.grade_display === 'grade_display_percentage' && (
          <Text style={styles.normalText}>
            {feedback?.points ? <>{feedback.points}%</> : 'Not graded'}
          </Text>
        )}

        {assignment?.grade_display === 'grade_display_points' && (
          <Text style={styles.normalText}>
            {feedback?.points ? (
              <>
                {feedback.points}/{assignment?.max_points} marks
              </>
            ) : (
              'Not graded'
            )}
          </Text>
        )}

        {assignment?.grade_display === 'grade_display_rubrics' &&
          assignment?.rubrics_grade_display === 'points' && (
            <Text style={styles.normalText}>
              {feedback?.points ? (
                <>
                  {feedback.points}/{assignment?.max_points} points
                </>
              ) : (
                'Not graded'
              )}
            </Text>
          )}

        {assignment?.grade_display === 'grade_display_rubrics' &&
          assignment?.rubrics_grade_display === 'labels' && (
            <Text style={styles.normalText}>
              {feedback?.points
                ? 'This is a label based rubrics task'
                : 'Not graded'}
            </Text>
          )}
      </View>
    </CustomPage>
  );
};

PDFFeedbackPage.defaultProps = {
  userAssignment: {},
  assignment: {},
};

PDFFeedbackPage.propTypes = {
  userAssignment: PropTypes.object,
  assignment: PropTypes.object,
};

export default PDFFeedbackPage;
