import StorageService from 'services/StorageService';
import TeacherHome from './teacher-home/TeacherHome';
import StudentHome from './student-home/StudentHome';
import TeacherOnboardingForm from './teacher-onboarding/TeacherOnboardingForm';
import { useEffect, useState } from 'react';
import { getMembers, getUserProfile } from 'apis/';
import { setUser as setReduxUser } from 'store/reducers/user-reducer';
import { setClassroomDetails } from 'store/reducers/classroom-reducer';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useErrorHandler } from 'hooks/';
import { setAppError } from 'store/reducers/app-error-reducer';
import { useClassroom, useUser } from 'store/selectors';
import PulsatingLoader from 'components/pulsating-loader/PulsatingLoader';

const Home = () => {
  const [userProfileLoading, setUserProfileLoading] = useState(false);

  const dispatch = useDispatch();
  const { channelId } = useParams();
  const errorHandler = useErrorHandler();
  const { onboardingComplete, members } = useClassroom();
  const reduxUser = useUser();
  const [user, setUser] = useState(reduxUser);

  const userProfile = async () => {
    try {
      // if the user profile has not been fetched yet show the loader
      if (!reduxUser?.id) setUserProfileLoading(true);
      const response = await getUserProfile(channelId);
      dispatch(setReduxUser(response));
      if (response?.role === 'teacher') {
        dispatch(
          setClassroomDetails({
            ...response?.classroom_details,
            onboardingComplete:
              response?.classroom_details?.onboarding_complete,
          }),
        );
      }
      StorageService.setObject('user', response);
      setUser(response);
      setUserProfileLoading(false);
    } catch (error) {
      errorHandler(error, () => {
        setUserProfileLoading(false);
        dispatch(setAppError(error));
      });
    }
  };

  const fetchMembers = async () => {
    try {
      if (members?.length > 0) return;
      const response = await getMembers(channelId);
      dispatch(setClassroomDetails({ members: response }));
    } catch (e) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    userProfile();
    if (user?.role === 'teacher') fetchMembers();
  }, []);

  return (
    <>
      {userProfileLoading && <PulsatingLoader text='Fetching Profile' />}

      {!userProfileLoading && (
        <div>
          {user?.role === 'teacher' && onboardingComplete !== null && (
            <>
              {onboardingComplete ? <TeacherHome /> : <TeacherOnboardingForm />}
            </>
          )}

          {user?.role === 'student' && <StudentHome />}
        </div>
      )}
    </>
  );
};

export default Home;
