import {
  NurtureIcon,
  User,
  Sheet,
  useToast,
  SubmissionStatusBadge,
  StudentSubmissionCard,
  StudentReflectionCard,
  Separator,
  EngagementScoreCard,
  AverageTimeSpentOnFeedbackCard,
  TeacherFeedbackCard,
  AlertDialog,
} from '@gonurture/design-system';
import PropTypes from 'prop-types';
import {
  useAiFeedbackTeacherActions,
  useCurrentAssignment,
  useCurrentUserAssignment,
  useNonGradedRubricsObjectives,
} from 'store/selectors';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { removeAttachment, userAssignmentShow } from 'apis/';
import { useDispatch } from 'react-redux';
import {
  updateUserAssignment,
  updateUserAssignmentSubmission,
} from 'store/reducers/user-assignments-reducer';
import { useErrorHandler } from 'hooks/';
import FeedbackAiChat from './feedback-ai-chat/FeedbackAiChat';
import UserAssignmentPagination from './UserAssignmentPagination';
import UserAssignmentDropdown from './UserAssignmentDropdown';
import FeedbackForm from './feedback-form/FeedbackForm';
import { clearAiFeedback } from 'store/reducers/ai-feedback-reducer';
import {
  clearAiFeedbackTeacherActions,
  setAiFeedbackTeacherActions,
} from 'store/reducers/ai-feedback-teacher-actions-reducer';
import {
  clearNonGradedRubricsObjectives,
  setNonGradedRubricsObjectives,
} from 'store/reducers/non-graded-rubrics-objectives-reducer';
import { getNotSelectedRubricsObjectives } from 'lib/rubrics-grading';
import GradedRubricsModal from './graded-rubrics-modal/GradedRubricsModal';
import FileUploader from 'components/attachments/FileUploader';
import DownloadUserAssignmentButton from 'components/exportable-feedback/DownloadUserAssignmentButton';
import UserAssignmentModalLoading from './UserAssignmentModalLoading';

const UserAssignmentModal = ({
  opened,
  defaultUserAssignmentId,
  sortedSubmissions,
  onClose,
}) => {
  const [userAssignmentId, setUserAssignmentId] = useState(null);
  const [userAssignmentLoading, setUserAssignmentLoading] = useState(false);
  const [showFeedbackSummary, setShowFeedbackSummary] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState(null);
  const [newAttachments, setNewAttachments] = useState([]);
  const [actionsTimerID, setActionsTimerID] = useState();
  const [gradedRubricsModalOpened, setGradedRubricsModalOpened] =
    useState(false);
  const { assignmentId, channelId } = useParams();
  const currentAssignment = useCurrentAssignment(assignmentId);
  const userAssignment = useCurrentUserAssignment(userAssignmentId);
  const { rubricsGrade: teacherActionRubricsGrade } =
    useAiFeedbackTeacherActions();
  const { rubricsTableDirty } = useNonGradedRubricsObjectives();
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();
  const { toast } = useToast();

  const feedback = userAssignment?.submissions?.[0]?.feedback || {};
  let reflection = feedback?.reflection;
  if (reflection?.three_two_one_comment.question_one) {
    reflection = {
      ...reflection,
      triple_response_questions: reflection.three_two_one_comment,
    };
  }

  const canEditFeedback = !reflection;
  const canCancelEdit =
    userAssignment?.feedback_status === 'feedback_complete' ||
    userAssignment?.feedback_status === 'feedback_sent';

  const availableUserAssignments =
    sortedSubmissions.length > 0
      ? sortedSubmissions
      : currentAssignment?.user_works;
  const currentIndex = availableUserAssignments?.findIndex(
    (userAssignment) => userAssignment.id === userAssignmentId,
  );

  const EDIT_FEEDBACK_MODE_KEY = 'edit_feedback_mode';

  const resetStoreDetails = () => {
    dispatch(clearAiFeedback());
    dispatch(clearAiFeedbackTeacherActions());
    dispatch(clearNonGradedRubricsObjectives());
  };

  const handleClose = () => {
    if (rubricsGradedCorrectly()) {
      resetStoreDetails();
      onClose();
    }
  };

  const locate = async (direction) => {
    if (!rubricsGradedCorrectly()) return;

    const movement = { prev: -1, next: 1 };

    try {
      // No need to move to next or prev if only one submission
      if (availableUserAssignments?.length === 1) return;

      if (currentIndex === -1) return;

      let newIndex = currentIndex + movement[direction];
      if (newIndex === -1)
        newIndex = availableUserAssignments?.length - 1 || currentIndex;
      if (newIndex >= availableUserAssignments?.length) newIndex = 0;
      const newUserAssignment = availableUserAssignments[newIndex];

      resetStoreDetails();
      setUserAssignmentId(newUserAssignment.id);
    } catch (e) {
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching student work',
          variant: 'destructive',
        });
      });
    }
  };

  const rubricsGradedCorrectly = () => {
    // If the teacher did not grade any rubrics, return true
    if (teacherActionRubricsGrade?.length === 0) return true;

    if (!rubricsTableDirty) return true;

    // If the teacher graded all the rubrics, return true
    if (
      teacherActionRubricsGrade?.length ===
      currentAssignment?.objectives?.length
    ) {
      return true;
    }

    const notSelectedRubricsObjectives = getNotSelectedRubricsObjectives(
      teacherActionRubricsGrade,
      currentAssignment?.objectives,
    );

    dispatch(
      setNonGradedRubricsObjectives({
        objectives: notSelectedRubricsObjectives,
      }),
    );

    toast({
      description: 'Please grade all the rubrics or unselect all the rubrics',
      variant: 'destructive',
    });

    return false;
  };

  const prev = () => locate('prev');

  const next = () => locate('next');

  const handleSubmissionUpload = async (attachment) => {
    setNewAttachments((prev) => {
      const updatedAttachments = [...prev, attachment];

      setTeacherUploadAction(updatedAttachments);

      return updatedAttachments;
    });
  };

  const handleSubmissionAttachmentReset = () => {
    const submission = userAssignment?.submissions[0];

    const updatedSubmission = {
      ...submission,
      attachments: [...submission.attachments, ...newAttachments],
    };
    dispatch(
      updateUserAssignmentSubmission({
        id: userAssignment.id,
        submission: updatedSubmission,
      }),
    );

    setNewAttachments([]);
  };

  const handleDeletedJustUploadedAttachment = (attachment) => {
    const updatedAttachments = newAttachments.filter(
      (att) => att.id !== attachment.id,
    );
    setNewAttachments((prev) => updatedAttachments); // eslint-disable-line no-unused-vars
    setTeacherUploadAction(updatedAttachments);
  };

  const handleDelete = async (attachmentId) => {
    try {
      setDeleteLoading(true);
      await removeAttachment(channelId, attachmentId);
      setDeleteLoading(false);
      setShowDeleteConfirmation(false);
      const submission = userAssignment?.submissions[0];

      const updatedSubmission = {
        ...submission,
        attachments: submission.attachments.filter(
          (attachment) => attachment.id !== attachmentId,
        ),
      };
      dispatch(
        updateUserAssignmentSubmission({
          id: userAssignment.id,
          submission: updatedSubmission,
        }),
      );
      setTeacherUploadAction(
        submission.attachments.filter(
          (attachment) => attachment.id !== attachmentId,
        ),
      );
      setAttachmentToDelete(null);
      setShowDeleteConfirmation(false);
    } catch (e) {
      errorHandler(e, () => {
        setDeleteLoading(false);
        toast({
          title: 'Error',
          description: 'Error occurred while deleting attachment',
          variant: 'destructive',
        });
      });
    }
  };

  const setTeacherUploadAction = (updatedAttachments) => {
    clearTimeout(actionsTimerID);

    const timerId = setTimeout(() => {
      dispatch(
        setAiFeedbackTeacherActions({
          studentSubmission: updatedAttachments,
        }),
      );
    }, 5000);

    setActionsTimerID(timerId);
  };

  const handleClickDeleteAttachment = (attachment) => {
    setAttachmentToDelete(attachment);
    setShowDeleteConfirmation(true);
  };

  const fetchUserAssignment = async (id, showLoader = true) => {
    try {
      if (showLoader) setUserAssignmentLoading(true);

      const result = await userAssignmentShow(channelId, id);
      dispatch(updateUserAssignment(result));
      setUserAssignmentLoading(false);
    } catch (e) {
      setUserAssignmentLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching student work',
          variant: 'destructive',
        });
      });
    }
  };

  const getEditFeedbackModeSettings = (userAssignmentId) => {
    let settings = localStorage.getItem(EDIT_FEEDBACK_MODE_KEY);

    try {
      settings = JSON.parse(settings) || {};
    } catch (e) {
      settings = {};
    }

    return settings[userAssignmentId];
  };

  const setEditFeedbackModeSettings = (userAssignmentId, value) => {
    let settings = localStorage.getItem(EDIT_FEEDBACK_MODE_KEY);

    try {
      settings = JSON.parse(settings) || {};
    } catch (e) {
      settings = {};
    }
    settings[userAssignmentId] = value;

    localStorage.setItem(EDIT_FEEDBACK_MODE_KEY, JSON.stringify(settings));
  };

  const handleEditFeedback = () => {
    setShowFeedbackSummary(false);
    setEditFeedbackModeSettings(userAssignmentId, 'true');
  };

  const handleCancelEditFeedback = () => {
    setShowFeedbackSummary(true);
    setEditFeedbackModeSettings(userAssignmentId, 'false');
  };

  useEffect(() => {
    setUserAssignmentId(defaultUserAssignmentId);
  }, [defaultUserAssignmentId]);

  useEffect(() => {
    if (userAssignmentId) {
      const showLoader = !userAssignment || !userAssignment.submissions;

      fetchUserAssignment(userAssignmentId, showLoader);
    }
  }, [userAssignmentId]);

  useEffect(() => {
    const editModeFeedbackSettings = getEditFeedbackModeSettings(
      userAssignment?.id,
    );
    setShowFeedbackSummary(
      (userAssignment?.feedback_status === 'feedback_complete' ||
        userAssignment?.feedback_status === 'feedback_sent') &&
        editModeFeedbackSettings !== 'true',
    );
  }, [userAssignment]);

  return (
    <Sheet
      contentClassName='w-full 2xl:w-3/4'
      opened={opened}
      showDefaultClose={false}
    >
      <div className='flex flex-col sm:flex-row'>
        {/* Left Column - Main Content */}
        <div className='w-full order-2 sm:order-1 sm:w-1/2 md:w-7/12 lg:w-8/12 p-6 sm:border-r sm:h-screen sm:overflow-y-scroll px-2 md:px-3'>
          <div className='flex justify-between mb-8'>
            {/*CLose Button*/}
            <div
              className='flex cursor-pointer items-center border border-[#D0D5DD] hover:bg-gray-100 py-1 px-4 font-semibold text-sm text-[#344054] rounded-lg transition-all duration-100'
              onClick={handleClose}
            >
              Close{' '}
              <NurtureIcon icon='x-close' className='ml-1 w-[16px] h-[16px]' />
            </div>

            {/*Top Pagination*/}
            <UserAssignmentPagination
              total={availableUserAssignments?.length}
              currentIndex={currentIndex}
              onNext={next}
              onPrev={prev}
            />
          </div>

          {userAssignmentLoading && <UserAssignmentModalLoading />}

          {!userAssignmentLoading && (
            <>
              <div className='flex justify-between items-center mb-8'>
                <div>
                  <User className='mb-2' user={userAssignment?.user || {}} />
                  <SubmissionStatusBadge submission={userAssignment} />
                </div>

                <div className='flex space-x-6 items-center shrink-0'>
                  <DownloadUserAssignmentButton
                    userAssignment={userAssignment}
                    assignment={currentAssignment}
                  />

                  <UserAssignmentDropdown userAssignment={userAssignment} />
                </div>
              </div>

              {/*Metric Cards (Engagement Score, Average Time spent t review feedback)*/}
              <div className='mb-8 lg:flex lg:space-x-3'>
                <div className='lg:w-1/2 mb-3 lg:mb-0'>
                  <EngagementScoreCard
                    maxEngagementScore={
                      userAssignment?.engagement_score?.max_score
                    }
                    engagementScore={
                      userAssignment?.engagement_score?.engagement_score
                    }
                  />
                </div>

                <div className='lg:w-1/2'>
                  <AverageTimeSpentOnFeedbackCard
                    averageTimeString={
                      userAssignment?.time_spent_reviewing_feedback || '?'
                    }
                  />
                </div>
              </div>

              {/*Student Submission*/}
              <div className='mb-8'>
                <>
                  <StudentSubmissionCard
                    submission={userAssignment?.submissions[0]}
                    onDeleteTeacherUploadedAttachment={
                      handleClickDeleteAttachment
                    }
                    uploader={
                      <FileUploader
                        attachableParams={{
                          submission_id: userAssignment?.submissions?.[0]?.id,
                          metadata: {
                            teacher_uploaded: true,
                          },
                        }}
                        onUpload={handleSubmissionUpload}
                        showChildren={false}
                        buttonView={true}
                        buttonViewContent={
                          <div className='flex items-center]'>
                            <NurtureIcon
                              className='mr-2'
                              icon='black-image-plus'
                            />{' '}
                            Add Attachments
                          </div>
                        }
                        buttonViewClassName='bg-[#FFFFFF] hover:bg-[#FFFFFF] border hover:border border-[#D0D5DD] hover:border-[#D0D5DD] font-semibold text-sm text-[#344054] hover:text-[#344054] rounded-lg'
                        onReset={handleSubmissionAttachmentReset}
                        onDelete={handleDeletedJustUploadedAttachment}
                      />
                    }
                  />

                  <AlertDialog
                    title='Delete?'
                    subtitle='Are you sure you wish to delete this attachment?'
                    opened={showDeleteConfirmation}
                    loading={deleteLoading}
                    cancelButtonClassName=''
                    actionButtonClassName='bg-red-500 hover:bg-red-600'
                    onAction={() => handleDelete(attachmentToDelete.id)}
                    onCancel={() => setShowDeleteConfirmation(false)}
                  />
                </>
              </div>

              <div className='mb-8'>
                {showFeedbackSummary && (
                  <>
                    <TeacherFeedbackCard
                      canEdit={canEditFeedback}
                      assessment={currentAssignment}
                      feedback={feedback}
                      onEdit={handleEditFeedback}
                      onOpenFullRubrics={() =>
                        setGradedRubricsModalOpened(true)
                      }
                    />

                    <GradedRubricsModal
                      opened={gradedRubricsModalOpened}
                      onClose={() => setGradedRubricsModalOpened(false)}
                      userAssignment={userAssignment}
                      assignment={currentAssignment}
                    />
                  </>
                )}

                {!showFeedbackSummary && (
                  <FeedbackForm
                    defaultFeedback={userAssignment?.submissions[0]?.feedback}
                    submission={userAssignment?.submissions[0]}
                    assignment={currentAssignment}
                    userAssignmentId={userAssignment?.id}
                    canCancelEdit={canCancelEdit}
                    onCancel={handleCancelEditFeedback}
                  />
                )}
              </div>

              <div className='mb-8'>
                <StudentReflectionCard reflection={reflection} />
              </div>
            </>
          )}

          <Separator className='mb-8' />

          <div className='flex items-center justify-between'>
            <User user={userAssignment?.user || {}} />

            {/*Bottom Pagination*/}
            <UserAssignmentPagination
              total={availableUserAssignments?.length}
              currentIndex={currentIndex}
              onNext={next}
              onPrev={prev}
            />
          </div>
        </div>

        {/* Right Column - AI Chat */}
        <div className='w-full h-screen overflow-y-scroll order-1 sm:order-2 sm:w-1/2 md:w-5/12 lg:w-4/12 sm:pl-0'>
          <FeedbackAiChat
            assignmentId={assignmentId}
            userAssignmentId={userAssignmentId}
          />
        </div>
      </div>
    </Sheet>
  );
};

UserAssignmentModal.defaultProps = {
  opened: false,
  sortedSubmissions: [],
};

UserAssignmentModal.propTypes = {
  opened: PropTypes.bool,
  defaultUserAssignmentId: PropTypes.string.isRequired,
  sortedSubmissions: PropTypes.array,
  onClose: PropTypes.func.isRequired,
};

export default UserAssignmentModal;
