import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import CustomPage from './CustomPage';
import PropTypes from 'prop-types';
import { epochToDate, format, titleize } from '@gonurture/design-system';
import InterFont from './fonts/Inter-VariableFont_opsz,wght.ttf';
import InterFontBold from './fonts/Inter_18pt-Bold.ttf';

const PDFSubmissionPage = ({ userAssignment, assignment }) => {
  const submission = userAssignment?.submissions?.[0];
  const studentFirstName = userAssignment?.user?.display_name.split(' ')[0];

  Font.register({
    family: 'Inter',
    fonts: [
      { src: InterFont, fontWeight: 'normal' },
      { src: InterFontBold, fontWeight: 'bold' },
    ],
  });

  const styles = StyleSheet.create({
    section: {
      marginBottom: 30,
    },
    heading: {
      fontSize: '12px',
      fontWeight: 'normal',
      marginBottom: 5,
      color: '#283E59',
    },
    normalText: {
      fontSize: '10px',
      fontWeight: 'normal',
      color: '#283E59',
    },
    bulletList: {
      marginLeft: 10,
      marginTop: 10,
    },
    bulletItem: {
      flexDirection: 'row',
      marginBottom: 3,
    },
    bulletPoint: {
      width: 10,
    },
    note: {
      marginTop: 10,
    },
    confidence: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    emoji: {
      fontSize: 14,
      fontFamily: 'NotoEmoji',
    },
    boldText: {
      fontWeight: 'bold',
    },
  });

  const SubHeader = () => (
    <>
      {userAssignment?.submitted ? (
        <>
          Submitted at{' '}
          {format(
            epochToDate(userAssignment?.submitted_at),
            'HH:mm [on] dddd MMMM DD YYYY',
          )}
        </>
      ) : (
        'Student has not submitted this task.'
      )}
    </>
  );

  return (
    <CustomPage
      userAssignment={userAssignment}
      assignment={assignment}
      headerText='submission for:'
      subHeader={<SubHeader />}
    >
      <View style={styles.section}>
        <Text style={styles.heading}>Attachments</Text>
        {submission?.attachments?.length > 0 ? (
          <>
            <Text style={styles.normalText}>
              {studentFirstName} submitted the following attachments for this
              task:
            </Text>
            <View style={styles.bulletList}>
              {submission?.attachments.map((attachment, index) => (
                <View key={index} style={styles.bulletItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.normalText}>{attachment.filename}</Text>
                </View>
              ))}
            </View>
            <Text style={{ ...styles.note, ...styles.normalText }}>
              Note that all attachments can only be previewed in the Nurture
              app.
            </Text>
          </>
        ) : (
          <>
            <Text style={styles.normalText}>
              {studentFirstName} did not submit any attachments for this task.
            </Text>
          </>
        )}
      </View>

      {/* Student Confidence Section */}
      <View style={styles.section}>
        <Text style={styles.heading}>Student Confidence</Text>
        <View>
          {submission?.confidence_level_emoji ? (
            <Text style={styles.normalText}>
              <Text style={styles.boldText}>
                {titleize(submission?.confidence_level_emoji)} Confidence
              </Text>{' '}
              on this task.
            </Text>
          ) : (
            <Text style={styles.normalText}>
              {studentFirstName} did not submit any confidence for this task.
            </Text>
          )}
        </View>
      </View>

      {/* Personal Comment Section */}
      <View style={styles.section}>
        <Text style={styles.heading}>Personal Comment For Teacher:</Text>
        {submission?.comment ? (
          <Text style={styles.normalText}>{submission.comment}.</Text>
        ) : (
          <Text style={styles.normalText}>
            {studentFirstName} did not submit any personal comment for this
            task.
          </Text>
        )}
      </View>
    </CustomPage>
  );
};

PDFSubmissionPage.defaultProps = {
  userAssignment: {},
  assignment: {},
};

PDFSubmissionPage.propTypes = {
  userAssignment: PropTypes.object,
  assignment: PropTypes.object,
};

export default PDFSubmissionPage;
