import { useCurrentUserAssignment } from '../../../../store/selectors';
import { useParams } from 'react-router-dom';
import User from '../../../../components/user/User';
import { BaseLearningOutcome } from '@gonurture/design-system';
import PropTypes from 'prop-types';
import GradedRubricsTable from './GradedRubricsTable';

const LearningOutcomesFeedback = ({ showScrollableSummary }) => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const currentAssignment = currentUserAssignment?.work;
  const feedback = currentUserAssignment?.submissions?.[0]?.feedback || {};

  const objectivesWithFeedback =
    currentAssignment?.objectives
      ?.filter((objective) => feedback.objectives?.includes(objective.id))
      .map((objective) => objective.id) || [];

  const rubricsColumns = currentAssignment?.rubrics_json?.[0]?.columns || [];
  const rubricsGrade =
    currentUserAssignment?.submissions?.[0]?.feedback?.rubric_grades || [];
  const isRubrics =
    currentAssignment?.grade_display === 'grade_display_rubrics';

  const getObjectivesCardDetails = (objective) => {
    if (isRubrics) {
      const rubricGradeForObjective = rubricsGrade.find(
        (grade) => grade.objective_id === objective.id,
      );
      const rubricColumnForObjective = rubricsColumns.find(
        (column) => column.id === rubricGradeForObjective.rubric_column_id,
      );

      return {
        body: objective.name,
        footer: rubricColumnForObjective.name,
        footerBgColor: '[#F9F5FF]',
        footerEnabled: true,
        footerIcon: 'purple-table',
        iconBgColor: '[#E9D7FE]',
      };
    } else {
      const objectiveHasFeedback = objectivesWithFeedback?.includes(
        objective.id,
      );

      return {
        body: objective.name,
        footer: objectiveHasFeedback ? 'Needs Work' : 'Success',
        footerBgColor: objectiveHasFeedback ? '[#FEF3F2]' : '[#F0FDF9]',
        footerEnabled: true,
        footerIcon: objectiveHasFeedback
          ? 'red-exclamation'
          : 'green-circle-check',
        iconBgColor: objectiveHasFeedback ? '[#FECDCA]' : '[#99F6E0]',
      };
    }
  };

  return (
    <>
      <div
        className={`${showScrollableSummary ? '' : 'mt-8 mx-auto w-full md:w-3/4 xl:w-1/2'}`}
      >
        {!showScrollableSummary && (
          <div className='mb-5'>
            <div className='text-2xl font-normal mb-1'>Feedback</div>
            <div className='text-[#344054]'>{currentAssignment?.title}</div>
          </div>
        )}

        <div className='mb-3'>
          <User user={currentAssignment?.user} />
        </div>

        <p className='mb-3 text-[#344054]'>
          Hey {currentUserAssignment?.user?.display_name?.split(' ')?.[0]}
        </p>

        <p className='mb-3 text-[#344054]'>
          Here is how you did on the <strong>learning outcomes</strong> for this
          assessment!
        </p>

        {currentAssignment?.objectives?.map((objective, index) => {
          const objectiveDetails = getObjectivesCardDetails(objective);

          return (
            <div className='mb-3' key={objective.id}>
              <BaseLearningOutcome {...objectiveDetails} number={index + 1} />
            </div>
          );
        })}
      </div>

      {isRubrics && (
        <div
          className={`${showScrollableSummary ? 'mt-8' : 'mx-auto w-full xl:w-11/12 my-8'}`}
        >
          <GradedRubricsTable
            userAssignment={currentUserAssignment}
            assignment={currentUserAssignment?.work}
            showPoints={showScrollableSummary}
          />
        </div>
      )}
    </>
  );
};

LearningOutcomesFeedback.propTypes = {
  showScrollableSummary: PropTypes.bool.isRequired,
};

export default LearningOutcomesFeedback;
