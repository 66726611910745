// src/app/rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './user-reducer';
import classroomReducer from './classroom-reducer';
import appErrorReducer from './app-error-reducer';
import assignmentsReducer from './assignments-reducer';
import userAssignmentsReducer from './user-assignments-reducer';
import aiAssignmentReducer from './ai-assignment-reducer';
import analyticsReducer from './analytics-reducer';
import aiFeedbackReducer from './ai-feedback-reducer';
import aiFeedbackTeacherActionsReducer from './ai-feedback-teacher-actions-reducer';
import nonGradedRubricsObjectivesReducer from './non-graded-rubrics-objectives-reducer';
import aiAssignmentPromptReducer from './ai-assignment-prompt-reducer';
import aiMessagesReducer from './ai-messages-reducer';

const rootReducer = combineReducers({
  user: userReducer,
  classroom: classroomReducer,
  appError: appErrorReducer,
  assignments: assignmentsReducer,
  userAssignments: userAssignmentsReducer,
  aiAssignment: aiAssignmentReducer,
  aiAssignmentPrompt: aiAssignmentPromptReducer,
  aiFeedback: aiFeedbackReducer,
  aiFeedbackTeacherActions: aiFeedbackTeacherActionsReducer,
  analytics: analyticsReducer,
  nonGradedRubricsObjectives: nonGradedRubricsObjectivesReducer,
  aiMessages: aiMessagesReducer,
});

export default rootReducer;
