import { Button, NurtureIcon } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const StudentSubmissionHeader = ({ activeState, onPrev, assessmentName }) => {
  return (
    <div className='flex justify-between items-center'>
      <Button outline className='mr-2 w-auto h-auto' onClick={onPrev}>
        <NurtureIcon icon='black-arrow-left' />
      </Button>

      {!['summary'].includes(activeState) && (
        <div className='flex justify-end items-center flex-grow'>
          <div>
            <div className='flex items-center justify-end'>
              <NurtureIcon icon='purple-file-check' />
              <div className='font-semibold text-[#344054] text-end ml-2'>
                Submit Assessment
              </div>
            </div>
            <div className='text-xs text-end'>{assessmentName}</div>
          </div>
        </div>
      )}
    </div>
  );
};

StudentSubmissionHeader.propTypes = {
  activeState: PropTypes.string.isRequired,
  onPrev: PropTypes.func.isRequired,
  assessmentName: PropTypes.string.isRequired,
};

export default StudentSubmissionHeader;
