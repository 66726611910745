import PropTypes from 'prop-types';
import { Dialog } from '@gonurture/design-system';
import GradedRubricsTable from 'pages/user-assignment/user-assignment-show/feedback-details/GradedRubricsTable';

const GradedRubricsModal = ({
  opened,
  onClose,
  userAssignment,
  assignment,
}) => {
  return (
    <Dialog
      opened={opened}
      onClose={onClose}
      // title={<Header />}
      onCloseButtonClicked={onClose}
      contentClassName='sm:!max-w-[635px] md:!max-w-[760px] lg:!max-w-[1000px] xl:!max-w-[1200px] overflow-y-scroll'
    >
      <div className='overflow-x-scroll'>
        <GradedRubricsTable
          assignment={assignment}
          userAssignment={userAssignment}
          showPoints={true}
        />
      </div>
    </Dialog>
  );
};

GradedRubricsModal.defaultProps = {
  opened: false,
  onClose: () => {},
  userAssignment: {},
  assignment: {},
};

GradedRubricsModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  userAssignment: PropTypes.object,
  assignment: PropTypes.object,
};

export default GradedRubricsModal;
