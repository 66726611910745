import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from 'store/selectors';
import {
  Emoji,
  Separator,
  titleize,
  AutoLinker,
} from '@gonurture/design-system';
import PropTypes from 'prop-types';

const StudentReflectionSummary = ({
  reflectionFeeling,
  reflectionComment,
  threeQuestionComment,
  twoQuestionComment,
  oneQuestionComment,
  reflectionType,
  allowEdit,
  onTransition,
}) => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const currentAssignment = currentUserAssignment?.work;

  const proseClasses = `
      prose-headings:font-semibold
      prose-ul:list-disc prose-ul:list-inside prose-ul:mb-2
      prose-ol:list-decimal prose-ol:list-inside prose-ol:mb-2
      prose-li:text-md prose-li:italic prose-li:text-[#475467]
      prose-p:italic prose-p:mb-2 prose-p:text-sm prose-p:text-[#344054]
  `;

  return (
    <div
      className={`${allowEdit ? 'mx-auto w-full md:w-3/4 xl:w-1/2 mt-10' : ''}`}
    >
      {allowEdit && (
        <>
          <div className='text-2xl mb-1'>Confirm your reflection</div>
          <p className='text-[#344054] mb-8 text-sm'>
            {currentAssignment?.title}
          </p>
        </>
      )}

      <div className='mb-5 flex flex-wrap justify-between items-center'>
        <p className='text-[#344054] flex items-center'>
          You are{' '}
          <Emoji
            className='mx-1'
            width={20}
            height={20}
            confidence={reflectionFeeling}
          />{' '}
          <strong>{titleize(reflectionFeeling)}</strong> &nbsp;with your
          teachers feedback.
        </p>
        {allowEdit && (
          <div
            className='text-[#7341C6] text-sm cursor-pointer font-semibold'
            onClick={() => onTransition('feedbackReflection')}
          >
            Change
          </div>
        )}
      </div>

      <Separator className={allowEdit ? 'my-6' : 'my-4'} />

      {reflectionType !== 'reflection_type_three_two_one' && (
        <div className='mb-5'>
          <p className='font-semibold text-[#101828]'>
            Personal Comment For Teacher
          </p>
          <div className='flex flex-wrap justify-between items-center'>
            <p className='mb-3'>
              {reflectionComment ? (
                <div className={proseClasses}>
                  <AutoLinker content={reflectionComment} />
                </div>
              ) : (
                'You did not leave any personal comment for your teacher'
              )}
            </p>
            {allowEdit && (
              <div
                className='text-[#7341C6] text-sm cursor-pointer font-semibold'
                onClick={() => onTransition('reflectionComment')}
              >
                {reflectionComment ? 'Change' : 'Add +'}
              </div>
            )}
          </div>
        </div>
      )}

      {reflectionType === 'reflection_type_three_two_one' && (
        <div className='mb-5'>
          <div className='flex flex-wrap justify-between items-center mb-3'>
            <p className='font-semibold'>3-2-1 Reflection for Teacher</p>
            {allowEdit && (
              <div
                className='text-[#7341C6] text-sm cursor-pointer font-semibold'
                onClick={() => onTransition('reflectionComment')}
              >
                Change
              </div>
            )}
          </div>

          <div className='mb-3'>
            <div className='text-[#101828] font-semibold'>
              What are 3 things you learned on this task?
            </div>
            <div className={proseClasses}>
              <AutoLinker content={threeQuestionComment} />
            </div>
          </div>

          <div className='mb-3'>
            <div className='text-[#344054] font-semibold'>
              What are 2 things you found interesting on this task?
            </div>
            <div className={proseClasses}>
              <AutoLinker content={twoQuestionComment} />
            </div>
          </div>

          <div className='mb-3'>
            <div className='text-[#344054] font-semibold'>
              What is 1 question you are still left with on this task?
            </div>
            <div className={proseClasses}>
              <AutoLinker content={oneQuestionComment} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StudentReflectionSummary.defaultProps = {
  allowEdit: true,
  reflectionType: '',
  onTransition: () => {},
};

StudentReflectionSummary.propTypes = {
  allowEdit: PropTypes.bool,
  onTransition: PropTypes.func,
  reflectionFeeling: PropTypes.string.isRequired,
  reflectionComment: PropTypes.string.isRequired,
  threeQuestionComment: PropTypes.string.isRequired,
  twoQuestionComment: PropTypes.string.isRequired,
  oneQuestionComment: PropTypes.string.isRequired,
  reflectionType: PropTypes.string,
};

export default StudentReflectionSummary;
