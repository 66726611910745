import { Input } from '@gonurture/design-system';
import { useEffect, useState } from 'react';
import { SUBJECTS } from './constants';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import SelectedItemBadge from './components/selected-item-badge/SelectedItemBadge';

const TeacherOnboardingSelectSubject = ({ selectedSubject, onSelect }) => {
  const [subjectsToDisplay, setSubjectsToDisplay] = useState([]);
  const [otherSelected, setOtherSelected] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const selectedSubjectClassNames = 'bg-[#F4EBFF] border-[#7F56D9]';
  const baseSubjectClassNames = 'border-[#D0D5DD] hover:bg-gray-50';

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const OTHER_SUBJECT_LOWERCASE = 'other';

  const reArrange = (subjects) => {
    const namedSubjects = subjects.filter(
      (subject) => subject.name.toLowerCase() !== OTHER_SUBJECT_LOWERCASE,
    );
    const otherSubjects = subjects.filter(
      (subject) => subject.name.toLowerCase() === OTHER_SUBJECT_LOWERCASE,
    );

    const sortedNamedSubjects = sortBy(namedSubjects, ['name']);
    const sortedOtherSubjects = sortBy(otherSubjects, ['name']);

    if (!selectedSubject) {
      return [...sortedNamedSubjects, ...sortedOtherSubjects];
    }

    // Separate the selected subject and nonSelected
    const selected = namedSubjects.filter(
      (subject) => subject.name === selectedSubject,
    );
    const nonSelected = namedSubjects.filter(
      (subject) => subject.name !== selectedSubject,
    );

    // Sort the nonSelected alphabetically
    const sortedNonSelected = sortBy(nonSelected, ['name']);

    // Combine selected subject at the top with the sorted nonSelected and other subjects
    return [...selected, ...sortedNonSelected, ...sortedOtherSubjects];
  };

  const search = (query) => {
    if (!query) return setSubjectsToDisplay(() => reArrange(SUBJECTS));

    const lowerCaseQuery = query.toLowerCase();
    const filteredSubjects = SUBJECTS.filter(
      (subject) =>
        subject.name.toLowerCase().startsWith(lowerCaseQuery) ||
        subject.name.toLowerCase() === OTHER_SUBJECT_LOWERCASE,
    );

    setSubjectsToDisplay(() => reArrange(filteredSubjects));
  };

  const selectSubject = (subject) => {
    if (subject.name.toLowerCase() === OTHER_SUBJECT_LOWERCASE) {
      setOtherSelected(true);
      onSelect(null);
      return;
    }

    setOtherSelected(false);
    onSelect(subject.name);
  };

  const handleOtherSubjectInputChange = (e) => {
    const value = e.target.value;
    onSelect(value);
  };

  const removeSelectedSubject = () => {
    if (otherSelected) {
      setOtherSelected(false);
    }
    onSelect(null);
  };

  useEffect(() => {
    search(searchQuery);

    if (
      selectedSubject &&
      !SUBJECTS.find((subject) => subject.name === selectedSubject)
    ) {
      setOtherSelected(true);
    }
  }, [selectedSubject]);

  useEffect(() => {
    search(searchQuery);
  }, [searchQuery]);

  return (
    <div>
      <div className='mb-8'>
        <div className='text-[#344054] text-xl font-semibold'>
          What subject is this class?
        </div>

        <div className='text-[#667085]'>Choose only one</div>
      </div>

      <div className='mb-8'>
        <Input placeholder='Search' onChange={handleInputChange} />
      </div>

      {selectedSubject && (
        <div className='mb-3'>
          <SelectedItemBadge
            item={selectedSubject}
            onRemove={removeSelectedSubject}
          />
        </div>
      )}

      <div className='md:flex md:flex-wrap'>
        {subjectsToDisplay.map((subject, index) => (
          <div key={index} className='w-full md:w-1/2 mb-3'>
            <div
              className={`${index % 2 === 0 ? 'md:mr-1' : 'md:ml-1'} ${selectedSubject === subject.name || (otherSelected && subject.name.toLowerCase() === OTHER_SUBJECT_LOWERCASE) ? selectedSubjectClassNames : baseSubjectClassNames} text-sm font-medium text-[#344054] p-2 rounded border cursor-pointer flex`}
              onClick={() => selectSubject(subject)}
            >
              <div>{subject.name}</div>
            </div>
          </div>
        ))}
      </div>

      {otherSelected && (
        <div className='mt-4'>
          <Input
            type='text'
            label='Subject'
            value={selectedSubject}
            onChange={handleOtherSubjectInputChange}
          />
        </div>
      )}
    </div>
  );
};

TeacherOnboardingSelectSubject.defaultProps = {
  selectedSubject: null,
  onSelect: () => {},
};

TeacherOnboardingSelectSubject.propTypes = {
  selectedSubject: PropTypes.string,
  onSelect: PropTypes.func,
};

export default TeacherOnboardingSelectSubject;
