import {
  Button,
  Dialog,
  NurtureIcon,
  // Select,
  TextArea,
  useToast,
} from '@gonurture/design-system';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useErrorHandler } from 'hooks/';
import { useParams } from 'react-router-dom';
import { useCurrentAssignment } from 'store/selectors';
import { updateAssignment as updateAssignmentApi } from 'apis/';
import { useDispatch } from 'react-redux';
import { updateAssignment } from 'store/reducers/assignments-reducer';

const FeedbackSettingsModal = ({ modalOpened, onClose, onSubmitted }) => {
  const [customInstructions, setCustomInstructions] = useState('');
  const [submitting, setSubmitting] = useState(false);
  // const [feedbackType, setFeedbackType] = useState('');

  const { assignmentId, channelId } = useParams();
  const currentAssignment = useCurrentAssignment(assignmentId) || {};

  const errorHandler = useErrorHandler();
  const { toast } = useToast();
  const dispatch = useDispatch();

  // const FEEDBACK_TYPES = [
  //   {
  //     label: 'Strengths, Improvements, Progress',
  //     value: 'Strengths, Improvements, Progress',
  //   },
  // ];

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const feedbackSettings = {
        custom_instructions: customInstructions,
      };

      const response = await updateAssignmentApi(channelId, assignmentId, {
        feedback_settings: feedbackSettings,
      });
      toast({
        title: 'Settings saved successfully 🎉',
        description:
          'Note that it might take up to a minute for the settings to be applied',
      });
      dispatch(updateAssignment(response));

      setSubmitting(false);
      onSubmitted();
    } catch (e) {
      errorHandler(e, () => {
        setSubmitting(false);
        toast({
          variant: 'destructive',
          description: 'Failed to save the settings',
        });
      });
    }
  };

  useEffect(() => {
    if (modalOpened) {
      setCustomInstructions(
        currentAssignment?.feedback_settings?.custom_instructions || '',
      );
    }
  }, [modalOpened]);

  return (
    <Dialog
      opened={modalOpened}
      showCloseButton={false}
      contentClassName='sm:max-w-[425px] md:max-w-[650px] h-full sm:h-auto'
    >
      <div>
        <div className='flex mb-4'>
          <NurtureIcon
            className='p-3 border shadow-sm rounded-lg'
            icon='gradient-stars'
          />
        </div>

        <div className='mb-4'>
          <div className='text-[#181D27] text-lg font-semibold'>
            Customise the Nurture Assistant
          </div>

          <div className='text-[#535862] text-sm'>
            This will instruct the assistant how to create feedback for all
            students on this assessment. These instructions are not visible to
            students.
          </div>
        </div>

        <div className='mb-8'>
          {/*<div className='mb-3'>*/}
          {/*  <Select*/}
          {/*    label='Feedback Type'*/}
          {/*    options={FEEDBACK_TYPES}*/}
          {/*    value={feedbackType}*/}
          {/*    onChange={setFeedbackType}*/}
          {/*  />*/}
          {/*</div>*/}

          <div>
            <TextArea
              label='Custom Instructions'
              rows={7}
              placeholder='e.g. Students regularly make spelling mistakes on this assessment. Pay close attention to their spelling when grading.'
              value={customInstructions}
              onChange={(e) => setCustomInstructions(e.target.value)}
            />
          </div>
        </div>

        <div className='md:flex space-x-2'>
          <div className='mb-2 md:mb-0 md:w-1/2'>
            <Button
              className='bg-white hover:bg-white border border-[#D0D5DD] hover:border-[#D0D5DD] text-[#344054] w-full'
              onClick={onClose}
              disabled={submitting}
            >
              Close
            </Button>
          </div>

          <div className='mb-2 md:mb-0 md:w-1/2'>
            <Button
              className='bg-[#7F56D9] border hover:bg-[#7F56D9] border-[#7F56D9] hover:border-[#7F56D9] text-[#FFFFFF] w-full'
              onClick={handleSubmit}
              withLoader={true}
              loading={submitting}
              loaderText='Saving...'
            >
              Save Settings
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

FeedbackSettingsModal.defaultProps = {
  modalOpened: false,
  onClose: () => {},
  onSubmitted: () => {},
};

FeedbackSettingsModal.propTypes = {
  modalOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitted: PropTypes.func,
};

export default FeedbackSettingsModal;
