import {
  Button,
  Emoji,
  epochToDate,
  format,
  NurtureIcon,
  SubmissionStatusBadge,
  Tooltip,
} from '@gonurture/design-system';
import { useClassroom } from 'store/selectors';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ASSIGNMENT_TYPES } from '../../../assignment/assignment-type/AssignmentType';

const UserAssignmentCard = ({ userAssignment }) => {
  const gradeDisplay = userAssignment?.work?.grade_display?.replace(
    'grade_display_',
    '',
  );
  const icon = ASSIGNMENT_TYPES[gradeDisplay]?.icon;
  const gradeDisplayTitle = ASSIGNMENT_TYPES[gradeDisplay]?.title;
  const dueAtDate = epochToDate(userAssignment?.work?.due_date);
  const assignmentTitle = userAssignment?.work?.title;

  const showNoGrade =
    userAssignment?.work?.grade_display === 'grade_display_ungraded' ||
    (userAssignment?.work?.grade_display === 'grade_display_rubrics' &&
      userAssignment?.work?.rubrics_grade_display === 'labels');

  const showPointsgrade =
    userAssignment?.work?.grade_display === 'grade_display_points' ||
    (userAssignment?.work?.grade_display === 'grade_display_rubrics' &&
      userAssignment?.work?.rubrics_grade_display !== 'labels');

  const showPercentageGrade =
    userAssignment?.work?.grade_display === 'grade_display_percentage';

  const navigate = useNavigate();
  const { channelId, tenantId } = useClassroom();

  const handleOpen = (query = null) => {
    let url = `/teams/${tenantId}/channels/${channelId}/user-assignment/${userAssignment.id}`;
    if (query) {
      url += `?${query}`;
    }

    navigate(url);
  };

  const getButton = () => {
    if (userAssignment?.reflected) {
      return {
        text: 'Open',
        className:
          'bg-[#FFFFFF] border border-[#7F56D9] text-[#7F56D9] hover:bg-[#FFFFFF] hover:border-[#7F56D9] hover:text-[#7F56D9]',
        onClick: () => handleOpen(),
      };
    } else if (userAssignment?.feedback_status === 'feedback_sent') {
      return {
        text: showNoGrade ? 'Reflect on Feedback' : 'Unlock Grade',
        className:
          'bg-[#15B79E] border-[#15B79E] text-[#FFFFFF] hover:bg-[#15B79E] hover:border-[#15B79E] hover:text-[#FFFFFF]',
        onClick: () => handleOpen('tab=feedback'),
      };
    } else {
      return {
        text: 'Open',
        className:
          'bg-[#7F56D9] border-[#7F56D9] text-white hover:bg-[#7F56D9] hover:border-[#7F56D9]',
        onClick: () => handleOpen(),
      };
    }
  };

  const button = getButton();

  return (
    <div className='border border-[#D0D5DD] rounded-lg p-3 sm:p-5'>
      <div className='mb-3'>
        <SubmissionStatusBadge submission={userAssignment} />
      </div>

      <div className='lg:flex justify-between'>
        <div className='flex items-center mb-4 lg:mb-0'>
          <div className='p-3 bg-[#D9D6FE] rounded-xl mr-4'>
            <NurtureIcon icon={icon} />
          </div>
          <div>
            <div className='text-[#000000] font-semibold text-xl'>
              {assignmentTitle}
            </div>
            <div className='flex flex-wrap items-center'>
              <div className='text-[#667085] text-sm mr-2'>
                {gradeDisplayTitle}
              </div>
              <NurtureIcon className='mr-2' icon='light-purple-asterisk' />
              <div className='text-[#667085] text-sm'>
                Due: {format(dueAtDate, 'MMM DD YYYY')}
              </div>
            </div>
          </div>
        </div>

        <div className='flex items-center space-x-10 mb-4 lg:mb-0'>
          <div className='flex flex-col justify-end'>
            <div className='text-[#101828] font-merriweather text-2xl mb-2'>
              {showPointsgrade && (
                <>
                  {userAssignment?.reflected
                    ? userAssignment?.average_grade
                    : '?'}
                  /{userAssignment?.work?.max_points}
                </>
              )}

              {showPercentageGrade && (
                <>
                  {userAssignment?.reflected
                    ? userAssignment?.average_grade
                    : '?'}
                  %
                </>
              )}
            </div>
            {!showNoGrade && (
              <div className='text-[#667085] text-sm font-semibold'>Grade</div>
            )}
          </div>

          <div className='flex flex-col justify-end'>
            <div className='text-[#101828] font-merriweather text-2xl mb-2'>
              {userAssignment.confidence_text && (
                <Tooltip
                  trigger={
                    <Emoji
                      width='30'
                      height='30'
                      confidence={userAssignment.confidence_text}
                    />
                  }
                >
                  {userAssignment.confidence_text}
                </Tooltip>
              )}

              {!userAssignment.confidence_text && <>?</>}
            </div>
            <div className='text-[#667085] text-sm font-semibold'>
              Confidence
            </div>
          </div>

          <div className='hidden lg:block'>
            <Button className={button.className} onClick={button.onClick}>
              {button.text}
            </Button>
          </div>
        </div>

        <div className='lg:hidden'>
          <Button
            className={`${button.className} w-full`}
            onClick={button.onClick}
          >
            {button.text}
          </Button>
        </div>
      </div>
    </div>
  );
};

UserAssignmentCard.defaultProps = {
  userAssignment: {},
};

UserAssignmentCard.propTypes = {
  userAssignment: PropTypes.object,
};

export default UserAssignmentCard;
