import { Button, NurtureIcon } from '@gonurture/design-system';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const StudentReflectionHeader = ({
  activeState,
  onPrev,
  activeStateConfig,
  assessmentName,
}) => {
  const navigate = useNavigate();

  const handlePrev = () => {
    if (activeState === 'learningOutcomes') {
      navigate(-1);
      return;
    }

    onPrev();
  };

  return (
    <div className='flex justify-between items-center'>
      <Button outline className='mr-2' onClick={handlePrev}>
        <NurtureIcon icon='black-arrow-left' />
      </Button>

      {activeState !== 'summary' && (
        <div className='flex justify-end items-center flex-grow'>
          <div>
            <div className='flex items-center justify-end'>
              <NurtureIcon icon={activeStateConfig.icon} />
              <div className='font-semibold text-[#344054] text-end ml-2'>
                {activeStateConfig.text}
              </div>
            </div>
            <div className='text-xs text-end'>{assessmentName}</div>
          </div>
        </div>
      )}
    </div>
  );
};

StudentReflectionHeader.propTypes = {
  activeState: PropTypes.string.isRequired,
  onPrev: PropTypes.func.isRequired,
  activeStateConfig: PropTypes.object.isRequired,
  assessmentName: PropTypes.string.isRequired,
};

export default StudentReflectionHeader;
