import { useEffect, useState } from 'react';
import { useErrorHandler } from 'hooks/';
import { useDispatch } from 'react-redux';
import { useAnalytics, useClassroom } from 'store/selectors';
import { truncateString, useToast } from '@gonurture/design-system';
import { insights } from 'apis/';
import { setAnalytics } from 'store/reducers/analytics-reducer';
import { Chart as MixedChart } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import { round } from 'lodash';
import DashboardGraphLoader from './DashboardGraphLoader';
import PropTypes from 'prop-types';

Chart.register(annotationPlugin);

const formatLabels = (labels) => {
  labels = labels.map((label) => truncateString(label, 20));
  if (labels.length <= 3) return labels;

  if (labels.length <= 11) {
    const newLabels = [];

    for (let i = 1; i <= labels.length; i++) {
      newLabels.push(i);
    }

    return newLabels;
  }

  return labels;
};

const DashboardGraph = ({ selectedUserId }) => {
  const [GraphDataLoading, setGraphDataLoading] = useState(false);
  const [GraphDataError, setGraphDataError] = useState('');

  const errorHandler = useErrorHandler();

  const dispatch = useDispatch();
  const { channelId } = useClassroom();
  const { toast } = useToast();

  const reduxAnalytics = useAnalytics();

  console.log(reduxAnalytics);

  const formattedConfidence = round(
    parseFloat(reduxAnalytics?.confidence_average || 0),
    1,
  );
  const formattedGrade = round(
    parseFloat(reduxAnalytics?.points_average || 0),
    1,
  );
  const formattedLabels = formatLabels(reduxAnalytics?.work_labels || []);

  const displayConfidence = (confidence) => {
    if (confidence <= 20) return '(😓 Low)';

    if (confidence <= 60) return '(😊 Neutral)';

    return '(😎 High)';
  };

  const skipped = (ctx, value) =>
    ctx.p0.skip || ctx.p1.skip ? value : undefined;
  const down = (ctx, value) =>
    ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

  const toggleLabel = (ctx, event) => {
    const hovered = ctx.hovered;
    ctx.hovered = !hovered;

    if (hovered) {
      ctx.hoverPosition = (event.x / ctx.chart.chartArea.width) * 100 + '%';
    }
    ctx.chart.update();
  };

  const annotation1 = {
    type: 'line',
    borderColor: '#FFA38F',
    borderDash: [6, 6],
    borderDashOffset: 0,
    borderWidth: 2,
    label: {
      display: (ctx) => ctx.hovered,
      content: () => `Average Confidence = ${formattedConfidence}%`,
      position: (ctx) => ctx.hoverPosition,
    },
    scaleID: 'y',
    value: formattedConfidence,
    enter(ctx, event) {
      toggleLabel(ctx, event);
    },
    leave(ctx, event) {
      toggleLabel(ctx, event);
    },
  };

  const annotation2 = {
    type: 'line',
    borderColor: '#6CBDBF',
    borderDash: [6, 6],
    borderDashOffset: 0,
    borderWidth: 2,
    label: {
      display: (ctx) => ctx.hovered,
      content: () => `Average Grade = ${formattedGrade}%`,
      position: (ctx) => ctx.hoverPosition,
    },
    scaleID: 'y',
    value: formattedGrade,
    enter(ctx, event) {
      toggleLabel(ctx, event);
    },
    leave(ctx, event) {
      toggleLabel(ctx, event);
    },
  };

  const tooltip = {
    yAlign: 'bottom',
    callbacks: {
      title: (ctx) => {
        const index = ctx[0]?.dataIndex;
        const labels = ctx[0]?.dataset.labels;

        return labels[index];
      },
    },
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        max: 100,
        beginAtZero: true,
        ticks: {
          stepSize: 20,
        },
      },
      x: {
        display: reduxAnalytics?.work_labels?.length < 12,
      },
    },
    plugins: {
      annotation: {
        annotations: {
          annotation1,
          annotation2,
        },
      },
      legend: {
        display: false,
      },
      tooltip,
    },
  };

  const data = {
    labels: formattedLabels,
    datasets: [
      {
        type: 'bar',
        label: 'Grade',
        data: reduxAnalytics?.points_list,
        borderColor: '#6CBDBF',
        backgroundColor: '#6CBDBF',
        barThickness: 6,
        barPercentage: 0.2,
        order: 2,
        labels: reduxAnalytics?.work_labels,
      },
      {
        type: 'line',
        label: 'Confidence',
        data: reduxAnalytics?.confidence_list,
        fill: false,
        borderColor: '#FFA38F',
        lineTension: 0.4,
        order: 1,
        segment: {
          borderColor: (ctx) =>
            skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, '#FFA38F'),
          borderDash: (ctx) => skipped(ctx, [6, 6]),
        },
        spanGaps: true,
        labels: reduxAnalytics?.work_labels,
      },
    ],
  };

  const fetchGraphData = async () => {
    try {
      setGraphDataLoading(true);
      setGraphDataError('');
      const response = await insights(channelId, selectedUserId);
      dispatch(setAnalytics(response));
      setGraphDataLoading(false);
      console.log(response);
    } catch (e) {
      errorHandler(e, () => {
        setGraphDataLoading(false);
        setGraphDataError(e.message);
        toast({
          variant: 'error',
          description: e.message,
        });
      });
    }
  };

  useEffect(() => {
    fetchGraphData();
  }, [selectedUserId]);

  return (
    <>
      {GraphDataError && !reduxAnalytics && (
        <div>Error Occurred when fetching analytics data</div>
      )}

      {GraphDataLoading && !GraphDataError && <DashboardGraphLoader />}

      {!GraphDataError && !GraphDataLoading && reduxAnalytics && (
        <div className='lg:flex lg:space-x-4'>
          <div className='lg:w-3/4 mb-4 lg:mb-0'>
            <MixedChart options={options} data={data} />
            <div className='text-center text-[#344054] mt-2 font-medium'>
              Assignments
            </div>
          </div>

          <div className='lg:w-1/4'>
            <div className='flex flex-col items-center mb-5'>
              <div className='flex justify-center items-center rounded-full bg-[#35294b] text-[#FFFFFF] font-semibold text-2xl w-16 h-16'>
                {reduxAnalytics.engagement_score}
              </div>
              <div className='mt-1 text-[#344054] font-semibold text-lg'>
                Engagement Score
              </div>
            </div>

            <div className='border border-[#D0D5DD] rounded-lg p-3 mb-5'>
              <div className='text-[#667085] text-[10px] mb-2'>
                AVERAGE CONFIDENCE
              </div>
              <div className='text-xl text-[#344054] font-medium'>
                {formattedConfidence}% {displayConfidence(formattedConfidence)}
              </div>
            </div>

            <div className='border border-[#D0D5DD] rounded-lg p-3'>
              <div className='text-[#667085] text-[10px] mb-2'>
                AVERAGE GRADE
              </div>
              <div className='text-xl text-[#344054] font-medium'>
                {formattedGrade}%
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

DashboardGraph.defaultProps = {
  selectedUserId: '',
};

DashboardGraph.propTypes = {
  selectedUserId: PropTypes.string,
};

export default DashboardGraph;
