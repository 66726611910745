import {
  Emoji,
  pluralize,
  Separator,
  titleize,
} from '@gonurture/design-system';
import Attachments from 'components/attachments/Attachments';
import PropTypes from 'prop-types';

const StudentSubmissionSummary = ({
  attachments,
  onEditAttachments,
  assignmentName,
  confidence,
  onEditConfidence,
  personalComment,
  onEditPersonalComment,
  allowEdit,
}) => {
  return (
    <div>
      {allowEdit && (
        <>
          <div>
            <div className='text-2xl text-[#000000] mb-3'>
              Confirm your submission
            </div>
            <div className='text-[#344054] text-sm'>{assignmentName}</div>
          </div>

          <Separator className='my-6' />
        </>
      )}

      <div className=''>
        <div className='text-[#000000] font-semibold mb-4'>Uploaded Work:</div>

        <div className='flex justify-between flex-wrap items-center mb-6'>
          {attachments.length > 0 ? (
            <div className='text-[#344054]'>
              You uploaded{' '}
              <strong>
                {attachments.length}{' '}
                {pluralize('attachment', attachments.length)}
              </strong>{' '}
              for your work
            </div>
          ) : (
            <div className='text-[#344054]'>
              No Curriculum Document Uploaded
            </div>
          )}

          {allowEdit && (
            <div
              className='text-sm text-[#7341C6] cursor-pointer font-semibold flex-grow text-end'
              onClick={onEditAttachments}
            >
              Change
            </div>
          )}
        </div>

        {attachments.length > 0 && (
          <div className=''>
            <Attachments attachments={attachments} canDelete={false} />
          </div>
        )}
      </div>

      <Separator className='my-6' />

      <div>
        <div className='text-[#000000] font-semibold mb-4'>Confidence:</div>

        <div className='flex justify-between flex-wrap items-center mb-3'>
          <div className='text-[#344054] flex flex-wrap'>
            You had{' '}
            {
              <Emoji
                className='mx-1'
                confidence={confidence}
                width='18'
                height='18'
              />
            }{' '}
            <strong>{titleize(confidence)} Confidence </strong> &nbsp; in your
            submission
          </div>

          {allowEdit && (
            <div
              className='text-sm text-[#7341C6] cursor-pointer font-semibold flex-grow text-end'
              onClick={onEditConfidence}
            >
              Change
            </div>
          )}
        </div>
      </div>

      <Separator className='my-6' />

      <div>
        <div className='text-[#000000] font-semibold mb-4'>
          Personal Comment For Teacher:
        </div>

        <div className='flex justify-between flex-wrap items-center mb-3'>
          <div className='text-[#344054] font-semibold italic'>
            {personalComment || 'No personal comment added'}
          </div>

          {allowEdit && (
            <div
              className='text-sm text-[#7341C6] cursor-pointer font-semibold flex-grow text-end'
              onClick={onEditPersonalComment}
            >
              Change
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

StudentSubmissionSummary.defaultProps = {
  attachments: [],
  confidence: null,
  personalComment: '',
  onEditAttachments: () => {},
  onEditConfidence: () => {},
  onEditPersonalComment: () => {},
  assignmentName: '',
  allowEdit: true,
};

StudentSubmissionSummary.propTypes = {
  attachments: PropTypes.array,
  confidence: PropTypes.string,
  personalComment: PropTypes.string,
  assignmentName: PropTypes.string,
  onEditAttachments: PropTypes.func,
  onEditConfidence: PropTypes.func,
  onEditPersonalComment: PropTypes.func,
  allowEdit: PropTypes.bool,
};

export default StudentSubmissionSummary;
