import { createSlice } from '@reduxjs/toolkit';
export const aiMessagesSlices = createSlice({
  name: 'AiMessages',
  initialState: {},
  reducers: {
    addAiMessage: (state, action) => {
      const { id, message } = action.payload;
      state[id] = [...(state[id] || []), message];
      return state;
    },
    updateAiMessage: (state, action) => {
      const { id, messages } = action.payload;
      state[id] = messages;
      return state;
    },
    clearAiMessage: (state, action) => {
      const id = action.payload;
      state[id] = [];
    },
    clearAiMessages: (state) => {
      for (const id in state) {
        delete state[id];
      }
      return state;
    },
  },
});

export const {
  addAiMessage,
  updateAiMessage,
  clearAiMessage,
  clearAiMessages,
} = aiMessagesSlices.actions;

export default aiMessagesSlices.reducer;
