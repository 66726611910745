import {
  AlertDialog,
  BaseFile,
  Button,
  bytesToHumanReadable,
  FileUpload,
  Label,
  useToast,
} from '@gonurture/design-system';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useErrorHandler } from 'hooks/';
import { useClassroom } from 'store/selectors';
import { addAttachment, removeAttachment } from 'apis/';
import AttachmentMetadata from './AttachmentMetadata';

const FileUploader = ({
  attachableParams,
  buttonView,
  buttonViewClassName,
  buttonViewContent,
  showChildren,
  onUpload,
  onReset,
  onDelete,
  onUpdate,
}) => {
  const [files, setFiles] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [fileUploading, setFileUploading] = useState({});
  const [justUploaded, setJustUploaded] = useState(false);
  const [uploadError, setUploadError] = useState({});
  const [reset, setReset] = useState(false);
  const [uploadedFileIds, setUploadedFileIds] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const errorHandler = useErrorHandler();
  const { channelId } = useClassroom();
  const { toast } = useToast();

  const uploadProgressMethod = (file) => (progressEvent) => {
    const progress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );

    setUploadProgress((prevProgress) => ({
      ...prevProgress,
      [file.name]: progress,
    }));
  };

  const handleUpload = async (files) => {
    try {
      setFiles(() => files);
      setUploading(true);
      setReset(false);
      const uploadPromises = Array.from(files).map((file) => uploadFile(file));
      await Promise.all(uploadPromises);
    } catch (e) {
      errorHandler(e);
    }
  };

  const uploadFile = async (file) => {
    try {
      setFileUploading((prev) => ({
        ...prev,
        [file.name]: true,
      }));

      const form = new FormData();
      form.append('file', file);
      for (let key in attachableParams) {
        if (typeof attachableParams[key] === 'object') {
          attachableParams[key] = JSON.stringify(attachableParams[key]);
        }

        form.append(key, attachableParams[key]);
      }
      const attachment = await addAttachment(
        channelId,
        form,
        uploadProgressMethod(file),
      );
      onUpload(attachment);
      setJustUploaded((prev) => ({ ...prev, [file.name]: true }));
      setUploadedFileIds((prev) => ({ ...prev, [file.name]: attachment.id }));
      setFileUploading((prev) => ({ ...prev, [file.name]: false }));

      return attachment;
    } catch (e) {
      errorHandler(e, () => {
        setUploadError((prev) => ({
          ...prev,
          [file.name]: 'Error occurred when uploading attachment',
        }));
      });
      return Promise.reject(e);
    }
  };

  const attachmentPropertiesFromUploadedFile = (file) => {
    return {
      filename: file.name,
      filesize: bytesToHumanReadable(file.size),
      filetype: file.type.split('/')[1],
    };
  };

  const handleDelete = async (file) => {
    try {
      const attachmentId = uploadedFileIds[file.name];

      if (!uploadError[file.name]) {
        setDeleteLoading(true);
        const removedAttachment = await removeAttachment(
          channelId,
          attachmentId,
        );
        setDeleteLoading(false);
        setShowDeleteConfirmation(false);
        if (typeof onDelete === 'function') onDelete(removedAttachment);
      }
      removeFileFromArray(file);
    } catch (e) {
      errorHandler(e, () => {
        setDeleteLoading(false);
        toast({
          title: 'Error',
          description: 'Error occurred while deleting attachment',
          variant: 'destructive',
        });
      });
    }
  };

  const removeFileFromArray = (file) => {
    const filesArray = Array.from(files);
    const filteredArray = filesArray.filter((f) => f.name !== file.name);

    const dataTransfer = new DataTransfer();
    filteredArray.forEach((f) => dataTransfer.items.add(f));
    setFiles(() => dataTransfer.files);
  };

  const handleAddMore = () => {
    setReset(true);
    if (typeof onReset === 'function') onReset();
  };

  return (
    <div>
      {!buttonView && <Label className='mb-3'>Upload Attachments</Label>}

      {(!uploading || reset || Array.from(files).length < 1) && (
        <>
          <FileUpload
            buttonViewClassName={buttonViewClassName}
            buttonViewContent={buttonViewContent}
            buttonView={buttonView}
            onUpload={handleUpload}
          />
        </>
      )}

      {uploading && !reset && (
        <div>
          {Array.from(files).map((file) => {
            return (
              <div className='mb-2' key={file.name}>
                <BaseFile
                  canDelete={true}
                  uploading={!!fileUploading[file.name]}
                  uploadingPercentage={uploadProgress[file.name]}
                  justUploaded={justUploaded[file.name]}
                  error={uploadError[file.name]}
                  onDelete={() => setShowDeleteConfirmation(true)}
                  {...attachmentPropertiesFromUploadedFile(file)}
                >
                  {showChildren && (
                    <AttachmentMetadata
                      attachmentId={uploadedFileIds[file.name]}
                      onUpdate={onUpdate}
                    />
                  )}
                </BaseFile>

                <AlertDialog
                  title='Delete?'
                  subtitle='Are you sure you wish to delete this attachment?'
                  opened={showDeleteConfirmation}
                  loading={deleteLoading}
                  cancelButtonClassName=''
                  actionButtonClassName='bg-red-500 hover:bg-red-600'
                  onAction={() => handleDelete(file)}
                  onCancel={() => setShowDeleteConfirmation(false)}
                />
              </div>
            );
          })}

          <div className='mt-4 flex justify-end'>
            <Button outline onClick={handleAddMore}>
              Add more
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

FileUploader.defaultProps = {
  buttonView: false,
  buttonViewClassName: undefined,
  buttonViewContent: undefined,
  showChildren: false,
  onUpload: () => {},
  onReset: () => {},
  onDelete: () => {},
  onUpdate: () => {},
};

FileUploader.propTypes = {
  attachableParams: PropTypes.object.isRequired,
  buttonView: PropTypes.bool,
  buttonViewClassName: PropTypes.string,
  buttonViewContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  showChildren: PropTypes.bool,
  onUpload: PropTypes.func,
  onReset: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default FileUploader;
