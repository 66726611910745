import { NurtureIcon } from '@gonurture/design-system';
import { useState } from 'react';
import FeedbackSettingsModal from './FeebackSettingsModal';

const FeedbackSettingsCard = () => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  return (
    <>
      <div className='rounded-lg p-[2px] bg-gradient-to-r from-[#4D6AD0] to-[#FF9D7E]'>
        <div className='lg:flex lg:justify-between lg:items-center bg-white p-3 sm:p-4 rounded-lg'>
          {/* Left Section */}
          <div className='mb-3 lg::mb-0'>
            <div className='flex items-center space-x-2'>
              <span className='text-[#7F56D9] text-xl font-semibold'>
                <NurtureIcon icon='gradient-stars' />
              </span>
              <div className='text-[#101828] font-semibold'>
                Customize the Nurture Assistant
              </div>
            </div>
            <p className='text-xs text-[#000000] mt-2'>
              Give the Nurture Assistant custom instructions to follow when
              grading this assessment.
            </p>
          </div>

          {/* Right Section */}
          <div
            className='text-sm text-[##7341C6] font-semibold flex items-center space-x-2 cursor-pointer'
            onClick={() => setShowFeedbackModal(true)}
          >
            <span className='text-[#7341C6]'>Feedback Settings</span>
            <NurtureIcon icon='purple-settings' />
          </div>
        </div>
      </div>

      <FeedbackSettingsModal
        modalOpened={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
        onSubmitted={() => setShowFeedbackModal(false)}
      />
    </>
  );
};

export default FeedbackSettingsCard;
