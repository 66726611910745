import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import CustomPage from './CustomPage';
import PropTypes from 'prop-types';
import { epochToDate, format } from '@gonurture/design-system';
import InterFont from './fonts/Inter-VariableFont_opsz,wght.ttf';
import InterFontBold from './fonts/Inter_18pt-Bold.ttf';

const PDFReflectionPage = ({ userAssignment, assignment }) => {
  const submission = userAssignment?.submissions?.[0];
  const feedback = submission?.feedback;
  const reflection = feedback?.reflection;
  const studentFirstName = userAssignment?.user?.display_name.split(' ')[0];

  const threeTwoOneReflectionType =
    !!reflection?.three_two_one_comment?.question_three;
  const commentPresent = reflection?.comment || threeTwoOneReflectionType;

  Font.register({
    family: 'Inter',
    fonts: [
      { src: InterFont, fontWeight: 'normal' },
      { src: InterFontBold, fontWeight: 'bold' },
    ],
  });

  const styles = StyleSheet.create({
    section: {
      marginBottom: 30,
    },
    heading: {
      fontSize: '12px',
      fontWeight: 'normal',
      marginBottom: 5,
      color: '#283E59',
    },
    normalText: {
      fontSize: '10px',
      fontWeight: 'normal',
      color: '#283E59',
    },
    bulletList: {
      marginLeft: 10,
      marginTop: 10,
    },
    bulletItem: {
      flexDirection: 'row',
      marginBottom: 3,
    },
    bulletPoint: {
      width: 10,
    },
    note: {
      marginTop: 10,
    },
    confidence: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    emoji: {
      fontSize: 14,
      fontFamily: 'NotoEmoji',
    },
    boldText: {
      fontWeight: 'bold',
    },
  });

  const SubHeader = () => (
    <>
      {reflection?.completed_at && (
        <>
          Reflection completed at{' '}
          {format(
            epochToDate(reflection?.completed_at),
            'HH:mm [on] dddd MMMM DD YYYY',
          )}
        </>
      )}

      {feedback?.published_at && !reflection?.completed_at && (
        <>Student has not yet completed their reflection</>
      )}

      {['feedback_started', 'feedback_complete'].includes(
        userAssignment?.feedback_status,
      ) && (
        <Text style={{ color: '#D92D20' }}>
          Teacher feedback started but not yet sent to student!
        </Text>
      )}

      {userAssignment?.feedback_status === 'not_started' && (
        <Text style={{ color: '#D92D20' }}>
          Teacher has not yet started feedback!
        </Text>
      )}
    </>
  );

  return (
    <CustomPage
      userAssignment={userAssignment}
      assignment={assignment}
      headerText='feedback reflection for:'
      subHeader={<SubHeader />}
    >
      <View style={styles.section}>
        <Text style={styles.heading}>Feedback Reflection</Text>
        {reflection?.emoji && (
          <Text style={styles.normalText}>
            {studentFirstName} is{' '}
            <Text style={{ fontWeight: 'bold' }}>{reflection.emoji}</Text> with
            teachers feedback on this task.
          </Text>
        )}

        {!reflection?.emoji && !feedback?.published_at && (
          <Text style={styles.normalText}>
            Student has not been sent feedback to complete reflection.
          </Text>
        )}

        {!reflection?.emoji && feedback?.published_at && (
          <Text style={styles.normalText}>
            Student has not yet completed their reflection.
          </Text>
        )}
      </View>

      {/* Reflection Comment */}
      <View style={styles.section}>
        <Text style={styles.heading}>
          Personal Reflection Comment for Teacher:
        </Text>

        {!commentPresent && !feedback?.published_at && (
          <Text style={styles.normalText}>
            Student has not been sent feedback to complete reflection.
          </Text>
        )}

        {!commentPresent && feedback?.published_at && (
          <Text style={styles.normalText}>
            The student did not include a personal reflection comment for their
            teacher on this task feedback.
          </Text>
        )}

        {commentPresent && !threeTwoOneReflectionType && (
          <Text style={styles.normalText}>{reflection.comment}</Text>
        )}

        {commentPresent && threeTwoOneReflectionType && (
          <View style={styles.normalText}>
            <View style={{ marginBottom: '10px', marginTop: '10px' }}>
              <Text
                style={{
                  color: '#344054',
                  fontWeight: 'bold',
                  marginBottom: '7px',
                }}
              >
                What are 3 things you learned on this task?
              </Text>
              <Text style={styles.normalText}>
                {reflection?.three_two_one_comment?.question_three}
              </Text>
            </View>

            <View style={{ marginBottom: '10px', marginTop: '10px' }}>
              <Text
                style={{
                  color: '#344054',
                  fontWeight: 'bold',
                  marginBottom: '7px',
                }}
              >
                What are 2 things you found interesting on this task?
              </Text>
              <Text style={styles.normalText}>
                {reflection?.three_two_one_comment?.question_two}
              </Text>
            </View>

            <View style={{ marginBottom: '10px', marginTop: '10px' }}>
              <Text
                style={{
                  color: '#344054',
                  fontWeight: 'bold',
                  marginBottom: '7px',
                }}
              >
                What is 1 question you are still left with?
              </Text>
              <Text style={styles.normalText}>
                {reflection?.three_two_one_comment?.question_one}
              </Text>
            </View>
          </View>
        )}
      </View>

      {/* Engagement Score Section */}
      <View style={styles.section}>
        <Text style={styles.heading}>
          Nurture Engagement Score for this task
        </Text>
        <Text style={styles.normalText}>
          {userAssignment?.engagement_score?.engagement_score}/
          {userAssignment?.engagement_score?.max_score}
        </Text>
      </View>
    </CustomPage>
  );
};

PDFReflectionPage.defaultProps = {
  userAssignment: {},
  assignment: {},
};

PDFReflectionPage.propTypes = {
  userAssignment: PropTypes.object,
  assignment: PropTypes.object,
};

export default PDFReflectionPage;
