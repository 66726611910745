import { createSlice } from '@reduxjs/toolkit';

export const assignmentsSlice = createSlice({
  name: 'Assignments',
  initialState: {},
  reducers: {
    addAssignments: (state, action) => {
      const assignments = action.payload;
      assignments.forEach((assignment) => {
        state[assignment.id] = {
          ...(state[assignment.id] || {}),
          ...assignment,
        };
      });
      return state;
    },
    addAssignment: (state, action) => {
      state[action.payload.id] = action.payload;
      return state;
    },
    updateAssignment: (state, action) => {
      const { id } = action.payload;
      state[id] = { ...state[id], ...action.payload };
      return state;
    },
    addAssignmentAttachment: (state, action) => {
      const { id } = action.payload;
      const currentAssignment = state[id];
      if (currentAssignment.attachments.length === 0) {
        currentAssignment.attachments = [action.payload.attachment];
      } else {
        currentAssignment.attachments = [
          ...currentAssignment.attachments,
          action.payload.attachment,
        ];
      }

      state[id] = currentAssignment;

      return state;
    },
    updateAssignmentAttachment: (state, action) => {
      const { id, attachment: attachmentToUpdate } = action.payload;
      const currentAssignment = state[id];
      currentAssignment.attachments = currentAssignment.attachments.map(
        (attachment) => {
          if (attachment.id === attachmentToUpdate.id) {
            return action.payload.attachment;
          }
          return attachment;
        },
      );
      state[id] = currentAssignment;
      return state;
    },
    removeAssignmentAttachment: (state, action) => {
      const { id, attachment: attachmentToRemove } = action.payload;
      const currentAssignment = state[id];
      currentAssignment.attachments = currentAssignment.attachments.filter(
        (attachment) => attachment.id !== attachmentToRemove.id,
      );
      state[id] = currentAssignment;
      return state;
    },
    removeAssignment: (state, action) => {
      const { id } = action.payload;
      delete state[id];
      return state;
    },
  },
});

export const {
  addAssignments,
  addAssignment,
  updateAssignment,
  removeAssignment,
  addAssignmentAttachment,
  updateAssignmentAttachment,
  removeAssignmentAttachment,
} = assignmentsSlice.actions;

export default assignmentsSlice.reducer;
