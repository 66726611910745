import { Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import InterFont from './fonts/Inter-VariableFont_opsz,wght.ttf';
import NurtureLogo from 'assets/images/nurture-logo.png';
import InterFontBold from './fonts/Inter_18pt-Bold.ttf';

const CustomPage = ({
  userAssignment,
  assignment,
  headerText,
  subHeader,
  children,
}) => {
  Font.register({
    family: 'Inter',
    fonts: [
      { src: InterFont, fontWeight: 'normal' },
      { src: InterFontBold, fontWeight: 'bold' },
      // { src: InterFontRegular, fontWeight: 'normal' },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      padding: 40,
      fontSize: 12,
      fontFamily: 'Inter',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: 40,
      fontSize: 14,
      fontWeight: 'bold',
    },
    assessmentDetails: {
      flexBasis: '75%',
    },
    logo: {
      width: 50,
    },
    content: {
      // marginTop: 40,
    },
    footer: {
      position: 'absolute',
      bottom: 20,
      left: 40,
      right: 40,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: '8px',
      fontWeight: 'normal',
      color: '#6E84A3',
    },
  });

  return (
    <Page size='A4' style={styles.page}>
      {/* Fixed Header */}
      <View style={styles.header} fixed>
        <View style={styles.assessmentDetails}>
          <Text
            style={{
              fontSize: '16px',
              color: '#101828',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <Text style={{ fontWeight: 'bold', flexGrow: 1 }}>
              {userAssignment?.user?.display_name.split(' ')[0]} {headerText}
            </Text>{' '}
            <Text style={{ fontWeight: 'normal', flexGrow: 1 }}>
              {assignment?.title}
            </Text>
          </Text>
          <Text
            style={{
              color: '#344054',
              fontSize: '10px',
              fontWeight: 'normal',
              marginTop: '7px',
            }}
          >
            {subHeader}
          </Text>
        </View>
        <Image style={styles.logo} src={NurtureLogo} />
      </View>

      <View style={styles.content}>{children}</View>

      {/* Fixed Footer */}
      <View style={styles.footer} fixed>
        <Text style={{ flexBasis: '85%' }}>
          {userAssignment?.user?.display_name} - {assignment?.title} - Task
        </Text>
        <Text
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </View>
    </Page>
  );
};

CustomPage.propTypes = {
  userAssignment: PropTypes.object.isRequired,
  assignment: PropTypes.object.isRequired,
  headerText: PropTypes.string.isRequired,
  subHeader: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomPage;
