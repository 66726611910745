import {
  NurtureIcon,
  CreateFromCodeCard,
  useToast,
} from '@gonurture/design-system';
import { useNavigate } from 'react-router-dom';
import { useClassroom } from 'store/selectors';
import HeaderWithBackButton from 'components/header-with-back-button/HeaderWithBackButton';
import { clearAiAssignmentPrompt } from 'store/reducers/ai-assignment-prompt-reducer';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { createAssignmentFromCode } from 'apis/';
import { addAssignment } from '../../../store/reducers/assignments-reducer';
import { useErrorHandler } from 'hooks/';

export const ASSIGNMENT_TYPES = {
  percentage: {
    title: 'Percentage Formative Assessment',
    description: 'Assessment graded out of 100%',
    icon: 'grading-percentage',
    value: 'percentage',
  },
  points: {
    title: 'Marks Formative Assessment',
    description: 'Assessment graded out of a set number of maximum marks',
    icon: 'grading-marks',
    value: 'points',
  },
  rubrics: {
    title: 'Rubrics Formative Assessment',
    description: 'Assessment graded using a rubric.',
    icon: 'grading-rubrics',
    value: 'rubrics',
  },
  ungraded: {
    title: 'Ungraded Formative Assessment',
    description:
      'Assessment that is not graded and focuses only on student confidence & reflection',
    icon: 'grading-ungraded',
    value: 'ungraded',
  },
};

const AssignmentType = () => {
  const [creatingFromCode, setCreatingFromCode] = useState(false);
  const navigate = useNavigate();
  const { channelId, tenantId } = useClassroom();
  const dispatch = useDispatch();

  const { toast } = useToast();
  const errorHandler = useErrorHandler();

  const assignmentCreatePage = (assignmentType) => {
    dispatch(clearAiAssignmentPrompt());
    navigate(
      `/teams/${tenantId}/channels/${channelId}/assignment/assistant/${assignmentType}`,
    );
  };

  const handleBack = () => navigate(`/teams/${tenantId}/channels/${channelId}`);

  const handleCreateFromCode = async (code) => {
    try {
      setCreatingFromCode(true);
      toast({
        title: 'Creation in progress!',
        description: 'Hang on a bit, this might take a few seconds.',
      });
      const createdAssessment = await createAssignmentFromCode(channelId, code);
      dispatch(addAssignment({ ...createdAssessment, from_code: true }));
      navigate(
        `/teams/${tenantId}/channels/${channelId}/assignment/${createdAssessment.id}/edit`,
      );
    } catch (e) {
      setCreatingFromCode(false);
      errorHandler(e, () => {
        toast({
          title: 'Error occurred when creating assignment',
          description: e.message,
          variant: 'error',
        });
      });
    }
  };

  return (
    <div className='py-8'>
      <HeaderWithBackButton header='New Task' onBack={handleBack} />

      <div className='mb-6'>
        <CreateFromCodeCard
          creating={creatingFromCode}
          onCreate={handleCreateFromCode}
        />
      </div>

      <div className='text-[#101828] font-medium text-lg mb-6'>
        What kind of task do you want to create?
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
        {Object.keys(ASSIGNMENT_TYPES).map((key) => (
          <div
            key={key}
            className='border rounded-lg p-6 bg-white hover:shadow-sm cursor-pointer'
            onClick={() => assignmentCreatePage(key)}
          >
            <div className='flex items-center mb-4'>
              <div className='p-3 bg-[#D9D6FE] rounded-xl'>
                <NurtureIcon icon={ASSIGNMENT_TYPES[key].icon} />
              </div>
            </div>
            <h3 className='text-xl font-semibold mb-2 text-[#101828]'>
              {ASSIGNMENT_TYPES[key].title}
            </h3>
            <p className='text-[#344054] text-sm'>
              {ASSIGNMENT_TYPES[key].description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssignmentType;
