import {
  Skeleton,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  NurtureIcon,
} from '@gonurture/design-system';

const UserAssignmentModalLoading = () => {
  return (
    <div>
      <div className='flex justify-between items-center mb-8'>
        <div>
          <div className='flex items-center mb-2'>
            <Skeleton className='rounded-full w-10 h-10 mr-2' />
            <Skeleton className='w-32 h-6' />
          </div>

          <div>
            <Skeleton className='w-32 h-6' />
          </div>
        </div>

        <div className='flex space-x-6 items-center shrink-0'>
          <Skeleton className='w-32 h-8' />

          <DropdownMenu>
            <DropdownMenuTrigger>
              <NurtureIcon icon='grey-ellipsis-vertical' />
            </DropdownMenuTrigger>

            <DropdownMenuContent className='min-w-[250px] sm:min-w-[300px] sm:max-w-[400px] overflow-x-scroll'>
              <DropdownMenuGroup>
                <DropdownMenuLabel className='font-normal'>
                  <Skeleton className='w-full h-6' />
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuLabel className='font-normal'>
                  <Skeleton className='w-full h-6' />
                </DropdownMenuLabel>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <div className='mb-8 lg:flex lg:space-x-3'>
        <div className='lg:w-1/2 mb-3 lg:mb-0'>
          <Skeleton className='w-full h-64' />
        </div>

        <div className='lg:w-1/2'>
          <Skeleton className='w-full h-64' />
        </div>
      </div>

      <div className='mb-8'>
        <div className='mb-3'>
          <Skeleton className='w-48 h-8' />
        </div>
        <Skeleton className='w-full h-64' />
      </div>

      <div className='mb-8'>
        <div className='mb-3'>
          <Skeleton className='w-48 h-8' />
        </div>
        <Skeleton className='w-full h-64' />
      </div>
    </div>
  );
};

export default UserAssignmentModalLoading;
