import { createRequest } from 'services/ApiService';

export const getMembers = async (channelId) => {
  try {
    return await createRequest({
      url: `teams/classrooms/${channelId}/members`,
      method: 'get',
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateChannel = async (channelId, data) => {
  try {
    return await createRequest({
      url: `teams/classrooms/${channelId}`,
      method: 'patch',
      data: data,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const clearAppToken = async (channelId) => {
  try {
    return await createRequest({
      url: `teams/classrooms/${channelId}/clear_app_token`,
      method: 'delete',
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const refreshMembersList = async (channelId) => {
  try {
    return await createRequest({
      url: `teams/classrooms/${channelId}/refresh_members_list`,
      method: 'get',
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
