import { useToast, ScrollArea } from '@gonurture/design-system';
import { useParams } from 'react-router-dom';
import AssignmentForm from 'components/assignment-form/AssignmentForm';
import BackButton from 'components/back-button/BackButton';
import { useClassroom, useCurrentAssignment } from 'store/selectors';
import { useEffect, useState } from 'react';
import { assignmentShow } from 'apis/';
import { addAssignment } from 'store/reducers/assignments-reducer';
import { clearUserAssignments } from 'store/reducers/user-assignments-reducer';
import { setAppError } from 'store/reducers/app-error-reducer';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'hooks/';
import AssessmentAiChat from 'components/ai-chat/AssessmentAiChat';

const AssignmentEdit = () => {
  const [assignmentLoading, setAssignmentLoading] = useState(false);

  const dispatch = useDispatch();
  const { toast } = useToast();
  const { assignmentId } = useParams();
  const { channelId, tenantId } = useClassroom();
  const errorHandler = useErrorHandler();

  const [backLocation, setBackLocation] = useState(
    `/teams/${tenantId}/channels/${channelId}/assignment/${assignmentId}`,
  );

  const currentAssignment = useCurrentAssignment(assignmentId);

  const fetchAssignment = async () => {
    try {
      setAssignmentLoading(true);
      const assignment = await assignmentShow(channelId, assignmentId);
      dispatch(addAssignment(assignment));
      dispatch(clearUserAssignments()); // clear the userAssignment in the store
      setAssignmentLoading(false);
    } catch (e) {
      setAssignmentLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching assignment',
          variant: 'error',
        });
        dispatch(
          setAppError({ message: 'Error occurred when fetching assignment' }),
        );
      });
    }
  };

  useEffect(() => {
    if (!currentAssignment.objectives) {
      fetchAssignment();
      setBackLocation(`/teams/${tenantId}/channels/${channelId}`);
    }

    if (currentAssignment?.from_code) {
      setBackLocation(
        `/teams/${tenantId}/channels/${channelId}/assignment/type`,
      );
    }
  }, [currentAssignment]);

  return (
    <>
      {assignmentLoading && <div>Loading...</div>}

      {!assignmentLoading && (
        <div className='flex flex-col sm:flex-row'>
          <div className='w-full order-2 sm:order-1 sm:w-1/2 md:w-7/12 lg:w-8/12 p-6 sm:border-r sm:h-screen sm:overflow-y-scroll px-2'>
            <div>
              <div className='my-5'>
                <BackButton to={backLocation} />
              </div>

              <AssignmentForm
                context='AssignmentEdit'
                defaultAssignmentId={assignmentId}
              />
            </div>
          </div>

          <div className='w-full h-screen overflow-y-scroll order-1 sm:order-2 sm:w-1/2 md:w-5/12 lg:w-4/12 sm:pl-0'>
            <AssessmentAiChat assignmentId={assignmentId} />
          </div>
        </div>
      )}
    </>
  );
};

export default AssignmentEdit;
