import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useCurrentUserAssignment } from 'store/selectors';
import { useDispatch } from 'react-redux';
import {
  AssessmentStats,
  AssignmentPageHeader,
  Badge,
  NurtureIcon,
  Tabs,
  useToast,
  EngagementScoreDialog,
} from '@gonurture/design-system';
import { useErrorHandler } from 'hooks/';
import { setAppError } from 'store/reducers/app-error-reducer';
import { updateUserAssignment } from 'store/reducers/user-assignments-reducer';
import { userAssignmentShow } from 'apis';
import BackButton from 'components/back-button/BackButton';
import AssignmentDetails from './assignment-details/AssignmentDetails';
import FeedbackDetails from './feedback-details/FeedbackDetails';
import PulsatingLoader from 'components/pulsating-loader/PulsatingLoader';
import StudentSubmission from './student-submission/StudentSubmission';
import StudentSubmissionSuccessModal from './student-submission/StudentSubmissionSuccessModal';
import StudentReflection from './student-reflection/StudentReflection';
import StudentReflectionSuccessModal from './student-reflection/StudentReflectionSuccessModal';

const UserAssignmentShow = () => {
  const [userAssignmentLoading, setUserAssignmentLoading] = useState(false);
  const [showSubmissionFlow, setShowSubmissionFlow] = useState(false);
  const [submissionSuccessModal, setSubmissionSuccessModal] = useState(false);
  const [reflectionSuccessModal, setReflectionSuccessModal] = useState(false);
  const [showEngagementScoreModal, setShowEngagementScoreModal] =
    useState(false);
  const [tabDefaultValue, setTabDefaultValue] = useState('assessment');
  const [showReflectionFlow, setShowReflectionFlow] = useState(false);
  const { channelId, userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const dispatch = useDispatch();
  const { toast } = useToast();
  const errorHandler = useErrorHandler();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line

  const fetchUserAssignment = async () => {
    try {
      setUserAssignmentLoading(true);
      const assignment = await userAssignmentShow(channelId, userAssignmentId);
      dispatch(updateUserAssignment(assignment));
      if (searchParams.get('tab') === 'feedback') {
        setShowReflectionFlow(true);
      }
      setUserAssignmentLoading(false);
    } catch (e) {
      setUserAssignmentLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching assignment',
          variant: 'error',
        });
        dispatch(
          setAppError({ message: 'Error occurred when fetching assignment' }),
        );
      });
    }
  };

  const handleSubmissionInitiated = () => {
    if (currentUserAssignment?.feedback_status === 'feedback_sent') {
      toast({
        variant: 'destructive',
        description:
          'You cannot make a submission after feedback has been sent',
      });
      return;
    }

    setShowSubmissionFlow(true);
  };

  const handleSubmissionFlowClosed = (submitted = false) => {
    setShowSubmissionFlow(false);
    if (submitted) {
      setSubmissionSuccessModal(true);
    }
  };

  const handleReflectionFlowClosed = () => {
    setTabDefaultValue('feedback');
    setShowReflectionFlow(false);
    setReflectionSuccessModal(true);
  };

  useEffect(() => {
    fetchUserAssignment();
  }, []);

  return (
    <>
      {/*Main Page View*/}
      {!showSubmissionFlow && !showReflectionFlow && (
        <>
          {submissionSuccessModal && (
            <StudentSubmissionSuccessModal
              onClose={() => setSubmissionSuccessModal(false)}
            />
          )}
          {reflectionSuccessModal && (
            <StudentReflectionSuccessModal
              onClose={() => setReflectionSuccessModal(false)}
            />
          )}
          {userAssignmentLoading && <PulsatingLoader text='Fetching' />}
          {!userAssignmentLoading && (
            <div className='mt-5'>
              <div className='mb-6 flex justify-between items-center flex-wrap'>
                <div>
                  <BackButton />
                </div>
              </div>
              <div className='mb-6'>
                <AssignmentPageHeader
                  title={currentUserAssignment?.work.title}
                  dueAt={currentUserAssignment?.work.due_date}
                  studentSubmittedAt={currentUserAssignment?.submitted_at}
                  feedbackPublishedAt={
                    currentUserAssignment?.work.feedback_published_at
                  }
                  reflectionCompletedAt={currentUserAssignment?.reflected_at}
                />
              </div>

              <div className='mb-6'>
                <AssessmentStats
                  engagementScore={`${currentUserAssignment?.engagement_score?.engagement_score}/${currentUserAssignment?.engagement_score?.max_score}`}
                  graded={true}
                  feedback_published={[
                    'feedback_complete',
                    'feedback_sent',
                    'feedback_started',
                  ].includes(currentUserAssignment?.feedback_status)}
                  confidence={
                    currentUserAssignment?.submissions?.[0]
                      ?.confidence_level_emoji
                  }
                  grade={
                    currentUserAssignment?.submissions?.[0]?.feedback?.points
                  }
                  reflected={!!currentUserAssignment?.reflected_at}
                  reflection={
                    currentUserAssignment?.submissions?.[0]?.feedback
                      ?.reflection?.emoji
                  }
                  onEngagementScoreClick={() =>
                    setShowEngagementScoreModal(true)
                  }
                  onConfidenceClick={() => setShowSubmissionFlow(true)}
                />
              </div>

              <div className='mb-6'>
                <Tabs
                  defaultValue={tabDefaultValue}
                  tabListClass='bg-[#F9F5FF] h-14 p-1.5'
                  tabTriggerClass='bg-transparent data-[state=active]:bg-[#E9D7FE] h-full'
                  tabs={[
                    {
                      name: (
                        <div className='flex'>
                          <div className='mr-1 sm:mr-3'>Assessment</div>
                          <Badge
                            className='bg-[#F4F3FF] hover:bg-[#F4F3FF] text-[#5925DC]'
                            text='New'
                          />
                        </div>
                      ),
                      content: (
                        <AssignmentDetails
                          assignment={currentUserAssignment?.work}
                          userAssignment={currentUserAssignment}
                          onStartSubmission={handleSubmissionInitiated}
                        />
                      ),
                      value: 'assessment',
                    },
                    {
                      name: (
                        <div className='flex items-center'>
                          <div className='flex-grow'>Feedback</div>
                          {currentUserAssignment?.feedback_status !==
                            'feedback_sent' && (
                            <NurtureIcon
                              className='ml-1 sm:ml-3 w-[17px] h-[17px]'
                              icon='disabled-locked'
                            />
                          )}
                        </div>
                      ),
                      content: <FeedbackDetails />,
                      value: 'feedback',
                      disabled:
                        currentUserAssignment?.feedback_status !==
                        'feedback_sent',
                    },
                  ]}
                />
              </div>

              <EngagementScoreDialog
                opened={showEngagementScoreModal}
                isTeacher={false}
                assessmentAttachmentsCount={
                  currentUserAssignment?.work?.attachments?.length || 0
                }
                feedbackAttachmentsCount={
                  currentUserAssignment?.submissions?.[0]?.feedback?.attachments
                    ?.length || 0
                }
                feedbackAvailable={
                  currentUserAssignment?.feedback_status === 'feedback_sent'
                }
                onClose={() => setShowEngagementScoreModal(false)}
              />
            </div>
          )}
        </>
      )}

      {/*Submission Flow*/}
      {showSubmissionFlow && (
        <StudentSubmission
          userAssignment={currentUserAssignment}
          onClose={handleSubmissionFlowClosed}
        />
      )}

      {/*Reflection Flow*/}
      {showReflectionFlow && (
        <StudentReflection onDone={handleReflectionFlowClosed} />
      )}
    </>
  );
};

export default UserAssignmentShow;
