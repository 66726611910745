import { ASSIGNMENT_TYPES } from 'pages/assignment/assignment-type/AssignmentType';
import {
  Button,
  epochToDate,
  format,
  NurtureIcon,
  ShareButton,
} from '@gonurture/design-system';
import { useNavigate } from 'react-router-dom';
import { useClassroom } from 'store/selectors';
import AssignmentOptionsDropdown from './AssignmentOptionsDropdown';
import PropTypes from 'prop-types';

const AssignmentCard = ({ assignment }) => {
  const gradeDisplay = assignment?.grade_display?.replace('grade_display_', '');
  const icon = ASSIGNMENT_TYPES[gradeDisplay]?.icon;
  const gradeDisplayTitle = ASSIGNMENT_TYPES[gradeDisplay]?.title;
  const dueAtDate = epochToDate(assignment?.due_date);
  const navigate = useNavigate();

  const { channelId, tenantId } = useClassroom();

  const navigateToAssignment = () => {
    navigate(
      `/teams/${tenantId}/channels/${channelId}/assignment/${assignment.id}`,
    );
  };

  return (
    <div className='border border-[#D0D5DD] rounded-lg p-3 sm:p-5'>
      <div className='flex lg:hidden justify-end space-x-4 items-center mb-3'>
        <AssignmentOptionsDropdown assignment={assignment} />

        <ShareButton
          className='shrink-0'
          code={assignment?.sharable_code}
          assessmentName={assignment?.title}
        />
      </div>

      <div className='lg:flex justify-between'>
        <div className='flex items-center mb-4 lg:mb-0'>
          <div className='p-3 bg-[#D9D6FE] rounded-xl mr-4'>
            <NurtureIcon icon={icon} />
          </div>
          <div>
            <div className='text-[#000000] font-semibold text-xl'>
              {assignment.title}
            </div>
            <div className='flex flex-wrap items-center'>
              <div className='text-[#667085] text-sm mr-2'>
                {gradeDisplayTitle}
              </div>
              <NurtureIcon className='mr-2' icon='light-purple-asterisk' />
              <div className='text-[#667085] text-sm'>
                Due: {format(dueAtDate, 'MMM DD YYYY')}
              </div>
            </div>
          </div>
        </div>

        <div className='flex items-center space-x-8 mb-4 lg:mb-0'>
          <div>
            <div className='text-[#101828] font-merriweather text-2xl'>
              {assignment?.submitted_students_count || 0}/
              {assignment?.total_students}
            </div>
            <div className='text-[#667085] text-sm font-semibold'>
              Submitted
            </div>
          </div>

          <div>
            <div className='text-[#101828] font-merriweather text-2xl'>
              {assignment?.reflected_students_count || 0}/
              {assignment?.total_students}
            </div>
            <div className='text-[#667085] text-sm font-semibold'>
              Reflected
            </div>
          </div>

          <div className='hidden lg:flex items-center space-x-2'>
            <Button
              className='bg-[#7F56D9] border-[#7F56D9] text-white hover:bg-[#7F56D9] hover:border-[#7F56D9]'
              onClick={navigateToAssignment}
            >
              Open
            </Button>

            <ShareButton
              className='shrink-0'
              code={assignment?.sharable_code}
              assessmentName={assignment?.title}
            />
          </div>

          <div className='hidden lg:block'>
            <AssignmentOptionsDropdown assignment={assignment} />
          </div>
        </div>

        <div className='lg:hidden'>
          <Button
            className='bg-[#7F56D9] border-[#7F56D9] text-white hover:bg-[#7F56D9] hover:border-[#7F56D9] w-full'
            onClick={navigateToAssignment}
          >
            Open
          </Button>
        </div>
      </div>
    </div>
  );
};

AssignmentCard.defaultProps = {
  assignment: {},
};

AssignmentCard.propTypes = {
  assignment: PropTypes.object,
};

export default AssignmentCard;
