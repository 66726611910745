import {
  AiAssistantChat,
  useToast,
  AlertDialog,
} from '@gonurture/design-system';
import PropTypes from 'prop-types';
import { addMessage, getMessages, resetChat, runThread } from 'apis/';
import { useEffect, useState } from 'react';
import { convertMessageToChatFormat } from 'lib/ai_helpers';
import {
  useAiFeedbackTeacherActions,
  useAiMessage,
  useClassroom,
  useCurrentAssignment,
  useCurrentUserAssignment,
} from 'store/selectors';
import { useErrorHandler } from 'hooks/';
import { useDispatch } from 'react-redux';
import StorageService from 'services/StorageService';
import { setAiFeedback } from 'store/reducers/ai-feedback-reducer';
import FeedbackSettingsModal from '../../feedback-settings/FeebackSettingsModal';
import { updateUserAssignment } from 'store/reducers/user-assignments-reducer';
import {
  addAiMessage,
  clearAiMessage,
  updateAiMessage,
} from '../../../../../store/reducers/ai-messages-reducer';

const FeedbackAiChat = ({ assignmentId, userAssignmentId }) => {
  const [fetchingChats, setFetchingChats] = useState(false);
  const [aiThinking, setAiThinking] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [showResetConfirmation, setShowResetConfirmation] = useState(false);
  const [error, setError] = useState(''); // eslint-disable-line
  const [streaming, setStreaming] = useState(false);
  const [streamMessage, setStreamMessage] = useState('');
  const [teacherActions, setTeacherActions] = useState([]);
  const [actionsTimerID, setActionsTimerID] = useState();

  const user = StorageService.getObject('user');
  const { channelId } = useClassroom();
  const errorHandler = useErrorHandler();
  const { toast } = useToast();
  const dispatch = useDispatch();

  const currentAssignment = useCurrentAssignment(assignmentId);
  const userAssignment = useCurrentUserAssignment(userAssignmentId);
  const userAssignmentAiMessages = useAiMessage(userAssignmentId) || [];

  const {
    learningObjectives: teacherActionLearningObjectives,
    grade: teacherActionGrade,
    personalFeedback: teacherActionPersonalFeedback,
    rubricsGrade: teacherActionRubricsGrade,
    studentSubmission: teacherActionStudentSubmission,
  } = useAiFeedbackTeacherActions();

  const getInitialMessages = async () => {
    try {
      dispatch(clearAiMessage(userAssignmentId));
      setFetchingChats(true);
      const { messages } = await getMessages(
        channelId,
        userAssignment?.thread_id,
      );

      dispatch(
        updateAiMessage({
          id: userAssignmentId,
          messages: messages?.map((message) =>
            convertMessageToChatFormat(message),
          ),
        }),
      );
      setFetchingChats(false);
    } catch (e) {
      errorHandler(e, () => {
        setFetchingChats(false);
        toast({
          description: 'Error occurred when fetching messages',
          variant: 'destructive',
        });
      });
    }
  };

  const handleSubmitPrompt = async (prompt) => {
    const newUserMessage = {
      contents: [{ type: 'text', value: prompt }],
      sender: { name: user.display_name, avatar: user.avatar_url },
      requiresUserAction: false,
    };

    dispatch(addAiMessage({ id: userAssignmentId, message: newUserMessage }));
    setAiThinking(true);
    setStreaming(false);

    try {
      await addMessageToThread({
        content: prompt,
        thread_id: userAssignment?.thread_id,
      });
    } catch (error) {
      console.error('Failed to get AI response:', error);
      setError('Failed to get AI response');
    } finally {
      setAiThinking(false);
    }
  };

  const addMessageToThread = async (promptData, teacherActions = {}) => {
    try {
      setError(null); // clear any existing error in case the user is retrying

      const data = {
        ...promptData,
        ...teacherActions,
        user_work_id: userAssignment?.id,
      };

      if (teacherActions?.teacher_actions) {
        setTeacherActions([]);
      }

      const response = await addMessage(channelId, data);
      const threadId = response.id;

      runThread({
        threadId,
        channelId,
        onMessage: handleStreamEvent,
      });
    } catch (error) {
      setError(error);
    }
  };

  const handleStreamEvent = async (event) => {
    const messageEvent = JSON.parse(event.data);

    switch (messageEvent.event) {
      case 'assistant.learning_objectives_feedback': {
        dispatch(setAiFeedback({ learningObjectives: messageEvent.data }));
        break;
      }

      case 'assistant.grade': {
        dispatch(setAiFeedback({ grade: messageEvent.data }));
        break;
      }

      case 'assistant.personal_feedback': {
        dispatch(setAiFeedback({ personalFeedback: messageEvent.data }));
        break;
      }

      case 'assistant.rubrics_grade': {
        dispatch(setAiFeedback({ rubricsGrade: messageEvent.data }));
        break;
      }

      case 'thread.message.delta': {
        const content = messageEvent?.data.delta.content[0].text.value;
        setStreaming(true);
        setStreamMessage((prev) => prev + content);
        break;
      }

      case 'thread.message.completed':
        handleMessageCompleted(messageEvent);
        break;

      case 'thread.error':
        handleErrorEvent(messageEvent);
        break;

      case 'thread.run.failed':
        handleErrorEvent(messageEvent);
        break;
    }
  };

  const handleErrorEvent = (event) => {
    console.log('error event', event);
    setError(
      'The Nurture Assistant encountered an error while processing your request.',
    );
    setStreaming(false);
    setStreamMessage('');
    setAiThinking(false);
    toast({
      description:
        'The Nurture Assistant encountered an error while processing your request.',
      variant: 'destructive',
    });
  };

  const handleMessageCompleted = (messageEvent) => {
    dispatch(
      addAiMessage({
        id: userAssignmentId,
        message: {
          contents: [
            { type: 'text', value: messageEvent.data.content[0].text.value },
          ],
          requiresUserAction: false,
        },
      }),
    );

    setError('');
    setAiThinking(false);
    setStreamMessage('');
    setStreaming(false);
    // firstTokenSent = false;
    // metricService.track({ event: 'ai_success', properties: { scope: 'assistant_chat' } });
    // trackDuration('assistant_chat');
    // scrollToRecentChat();
  };

  const cleanUp = (message) => {
    return message.replace(/【[0-9]{1,2}:[0-9]{1,2}†source】/g, '');
  };

  const getAttachmentFromAiFileId = (fileId) => {
    return userAssignment?.submissions?.[0]?.attachments.find(
      (attachment) => attachment.ai_file_id === fileId,
    );
  };

  const replaceWithFileId = (text) => {
    // Regular expression to match the different variations of the prefix
    const regex = /nurture[_-]?attachment[_-]?(assistant-\w+)/g;

    // Replace each match with just the extracted file ID
    return text.replace(regex, (match, fileId) => {
      const attachment = getAttachmentFromAiFileId(fileId);
      return (
        '<b>' +
        attachment?.filename +
        '</b>  <a target="_blank" class="text-blue-500" href="' +
        attachment?.view_url +
        '">view</a>'
      );
    });
  };

  const updateTeacherActions = (action) => {
    const updatedActions = teacherActions.filter(
      (prevAction) => prevAction.type !== action.type,
    );
    updatedActions.push(action);

    setTeacherActions(updatedActions);

    clearTimeout(actionsTimerID);

    const timerId = setTimeout(() => {
      // addMessageToThread(
      //   { thread_id: userAssignment?.thread_id },
      //   { teacher_actions: updatedActions },
      // );
    }, 5000);

    setActionsTimerID(timerId);
  };

  const handleReset = async () => {
    try {
      setError(null); // clear any existing error in case the user is retrying
      setResetLoading(true);

      const response = await resetChat(
        channelId,
        userAssignment?.thread_id,
        userAssignment?.id,
      );
      const threadId = response.id;
      dispatch(
        updateUserAssignment({ ...userAssignment, thread_id: threadId }),
      );

      setResetLoading(false);
      setShowResetConfirmation(false);
      dispatch(clearAiMessage(userAssignmentId));
      setStreamMessage('');

      runThread({
        threadId,
        channelId,
        onMessage: handleStreamEvent,
      });
      setFetchingChats(false);
    } catch (error) {
      setResetLoading(false);
      setFetchingChats(false);
      setError(error);
    }
  };

  useEffect(() => {
    if (userAssignment?.thread_id) {
      getInitialMessages();
    } else if (userAssignment?.submissions) {
      dispatch(clearAiMessage(userAssignmentId));
      setFetchingChats(true);
      handleReset();
    }
  }, [userAssignment?.id, userAssignment?.thread_id]);

  useEffect(() => {
    dispatch(clearAiMessage(userAssignmentId));
    setFetchingChats(true);
  }, [userAssignmentId]);

  useEffect(() => {
    if (teacherActionLearningObjectives) {
      updateTeacherActions({
        type: 'learning_objectives',
        value: teacherActionLearningObjectives,
      });
    }
  }, [teacherActionLearningObjectives]);

  useEffect(() => {
    if (teacherActionGrade) {
      updateTeacherActions({
        type: 'grade',
        value: teacherActionGrade,
      });
    }
  }, [teacherActionGrade]);

  useEffect(() => {
    if (teacherActionPersonalFeedback) {
      updateTeacherActions({
        type: 'personal_feedback',
        value: teacherActionPersonalFeedback,
      });
    }
  }, [teacherActionPersonalFeedback]);

  useEffect(() => {
    if (
      teacherActionStudentSubmission &&
      teacherActionStudentSubmission.length > 0
    ) {
      updateTeacherActions({
        type: 'student_submission',
        value: teacherActionStudentSubmission,
      });
    }
  }, [teacherActionStudentSubmission]);

  useEffect(() => {
    if (
      teacherActionRubricsGrade &&
      teacherActionRubricsGrade.length === currentAssignment?.objectives?.length
    ) {
      updateTeacherActions({
        type: 'rubrics_grade',
        value: teacherActionRubricsGrade,
      });
    }
  }, [teacherActionRubricsGrade]);

  return (
    <>
      <div
        className='h-full
        prose-pre:whitespace-pre-wrap prose-pre:break-words prose-pre:overflow-auto prose-pre:bg-gray-100
        prose-pre:rounded-md prose-pre:text-sm prose-pre:p-2
        prose-table:table-auto prose-table:border-collapse
        prose-table:border prose-table:overflow-x-auto prose-table:w-full
        prose-table:my-3 prose-th:border prose-th:p-2 prose-td:text-sm prose-th:text-sm
        prose-td:border prose-td:p-2 prose-tr:even:bg-gray-50
        prose-p:mb-2 prose-p:text-sm prose-headings:font-semibold
        prose-ul:list-disc prose-ul:list-inside prose-li:text-sm prose-li:mb-1 prose-ul:mb-2
        prose-ol:list-decimal prose-ol:list-inside prose-ol:mb-2
    '
      >
        <AiAssistantChat
          messages={userAssignmentAiMessages.map((message) => ({
            ...message,
            contents: message.contents.map((content) => ({
              ...content,
              value: replaceWithFileId(cleanUp(content.value)),
            })),
          }))}
          onSubmitPrompt={handleSubmitPrompt}
          aiThinking={aiThinking}
          streaming={streaming}
          streamText={streamMessage}
          fetchingChats={fetchingChats}
          showFooter={true}
          onFeedbackSettings={() => setShowFeedbackModal(true)}
          onResetChat={() => setShowResetConfirmation(true)}
        />
      </div>

      <FeedbackSettingsModal
        modalOpened={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
        onSubmitted={() => setShowFeedbackModal(false)}
      />

      <AlertDialog
        title='Reset?'
        subtitle='Proceeding will clear all prior messages with the assistant, and any existing context from the current chat will be lost. Do you want to proceed?'
        opened={showResetConfirmation}
        loading={resetLoading}
        cancelButtonClassName=''
        actionButtonClassName='bg-red-500 hover:bg-red-600'
        onAction={handleReset}
        onCancel={() => setShowResetConfirmation(false)}
      />
    </>
  );
};

FeedbackAiChat.propTypes = {
  userAssignmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  assignmentId: PropTypes.string.isRequired,
};

export default FeedbackAiChat;
