import * as Sentry from '@sentry/react';

const useErrorHandler = () => {
  return (error, callback = null) => {
    if (callback && typeof callback === 'function') callback();

    Sentry.captureException(error);
  };
};

export default useErrorHandler;
