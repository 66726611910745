import ExportableFeedback from './ExportableFeedback';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { NurtureIcon } from '@gonurture/design-system';

const DownloadUserAssignmentButton = ({ userAssignment, assignment }) => {
  const studentName = userAssignment?.user?.display_name.split(' ')[0];
  return (
    <PDFDownloadLink
      document={
        <ExportableFeedback
          userAssignment={userAssignment}
          assignment={assignment}
        />
      }
      fileName={`${studentName}-${assignment?.title}.pdf`}
    >
      {({ loading }) => (
        <div className='flex border rounded-lg p-2 text-sm text-[#344054] cursor-pointer shrink-0 font-semibold shadow-sm'>
          {loading ? 'Exporting...' : 'Export as PDF'}
          <NurtureIcon icon='black-file-download' className='ml-2' />
        </div>
      )}
    </PDFDownloadLink>
  );
};

DownloadUserAssignmentButton.propTypes = {
  userAssignment: PropTypes.object.isRequired,
  assignment: PropTypes.object.isRequired,
};

export default DownloadUserAssignmentButton;
