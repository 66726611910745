import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from 'store/selectors';
import { Button } from '@gonurture/design-system';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

const StudentReflectionSuccessModal = ({ onClose }) => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const currentAssignment = currentUserAssignment?.work;
  const feedback = currentUserAssignment?.submissions?.[0]?.feedback || {};

  const showNoGrade =
    currentAssignment?.grade_display === 'grade_display_ungraded' ||
    (currentAssignment?.grade_display === 'grade_display_rubrics' &&
      currentAssignment?.rubrics_grade_display === 'labels');

  const grade = () => {
    if (
      currentAssignment?.grade_display === 'grade_display_points' ||
      currentAssignment?.grade_display === 'grade_display_rubrics'
    ) {
      return `${feedback?.points} / ${currentAssignment?.max_points}`;
    } else if (
      currentAssignment?.grade_display === 'grade_display_percentage'
    ) {
      return `${feedback?.points}%`;
    }
  };

  useEffect(() => {
    // Disable scrolling when the component mounts
    document.body.style.overflow = 'hidden';

    // Enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const Animation = () => {
    return (
      <DotLottieReact
        src='https://lottie.host/ff87f335-4f8c-463e-a0f2-a13339c4a02f/VCKBnG3mwO.json'
        autoplay
      />
    );
  };

  return (
    <div>
      <div className='fixed inset-0 backdrop-blur-sm'></div>
      <div className='fixed inset-0 flex items-center justify-center z-50 max-w-[300px] sm:max-w-[450px] mx-auto'>
        <div
          className='border-2 border-black rounded-lg px-4 md:px-8 py-4 pt-18 text-center mb-8 bg-white z-51'
          style={{ boxShadow: '1px 5px' }}
        >
          <div className='mb-8'>
            <Animation />
          </div>

          <div className='text-3xl text-[#101828] mb-5'>
            Well done{' '}
            {currentUserAssignment?.user?.display_name?.split(' ')?.[0]}!
          </div>
          <p className='text-[#344054] mb-6'>
            {showNoGrade && (
              <>
                Your engagement score was <strong>10/10</strong>
              </>
            )}

            {!showNoGrade && (
              <>
                You got <strong>{grade()}</strong> on this task and your
                engagement score was <strong>10/10</strong>
              </>
            )}
          </p>
          <Button
            className='bg-[#7F56D9] text-white hover:bg-[#7F56D9] w-full mb-4'
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

StudentReflectionSuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default StudentReflectionSuccessModal;
