import User from '../../../../components/user/User';
import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from '../../../../store/selectors';
import { TextArea } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const StudentReflectionComment = ({
  reflectionCommentValue,
  onChangeReflectionComment,
  threeValue,
  onChangeThreeQuestionComment,
  twoValue,
  onChangeTwoQuestionComment,
  oneValue,
  onChangeOneQuestionComment,
  reflectionType,
}) => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const currentAssignment = currentUserAssignment?.work;

  const SUGGESTIONS = [
    'The most important thing I learned was...',
    'What I enjoyed the most was...',
    'What can I do now that I could not do last week/month/year...',
    'Next time I should focus on...',
  ];

  return (
    <div className='my-8 mx-auto w-full md:w-3/4 xl:w-1/2'>
      <div className='mb-5'>
        <User user={currentAssignment?.user} />
      </div>

      {reflectionType !== 'reflection_type_three_two_one' && (
        <>
          <div className='text-[#344054] mb-5'>
            Do you have any personal comment to add?
          </div>

          <div className='mb-4'>
            <TextArea
              label='Personal Reflection Comment'
              rows={8}
              value={reflectionCommentValue}
              onChange={onChangeReflectionComment}
              placeholder='Tell your teacher how long it took you or where you think you could have done better.'
            />
          </div>
        </>
      )}

      {reflectionType === 'reflection_type_three_two_one' && (
        <>
          <div className='text-[#344054] mb-5'>
            Answer these three questions to complete your reflection.
          </div>

          {/*three questions*/}
          <div className='mb-4'>
            <TextArea
              label='What are 3 things you learned on this task?'
              rows={8}
              value={threeValue}
              onChange={onChangeThreeQuestionComment}
              placeholder='The 3 things I learned on this task are...'
            />
          </div>

          {/*two questions*/}
          <div className='mb-4'>
            <TextArea
              label='What are 2 things you found interesting on this task?'
              rows={8}
              value={twoValue}
              onChange={onChangeTwoQuestionComment}
              placeholder='The 2 things I found interesting are...'
            />
          </div>

          {/*one question*/}
          <div className='mb-4'>
            <TextArea
              label='What is 1 question you are still left with on this task?'
              rows={8}
              oneValue={oneValue}
              onChange={onChangeOneQuestionComment}
              placeholder='The 1 question I am still left with on this task is...'
            />
          </div>
        </>
      )}

      <div className='text-[#344054]'>
        <div className='text-sm mb-3'>
          Some suggestions to get you thinking and help your teacher give you
          better feedback :
        </div>

        <ul className='list-disc list-inside'>
          {SUGGESTIONS.map((suggestion, index) => (
            <li key={index} className='text-sm mb-1'>
              {suggestion}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

StudentReflectionComment.defaultProps = {
  reflectionCommentValue: undefined,
  threeValue: undefined,
  twoValue: undefined,
  oneValue: undefined,
  reflectionType: '',
  onChangeReflectionComment: () => {},
  onChangeThreeQuestionComment: () => {},
  onChangeTwoQuestionComment: () => {},
  onChangeOneQuestionComment: () => {},
};

StudentReflectionComment.propTypes = {
  reflectionCommentValue: PropTypes.string,
  threeValue: PropTypes.string,
  twoValue: PropTypes.string,
  oneValue: PropTypes.string,
  reflectionType: PropTypes.string,
  onChangeReflectionComment: PropTypes.func,
  onChangeThreeQuestionComment: PropTypes.func,
  onChangeTwoQuestionComment: PropTypes.func,
  onChangeOneQuestionComment: PropTypes.func,
};

export default StudentReflectionComment;
