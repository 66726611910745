import React from 'react';
import ReactDOM from 'react-dom/client';
import '@gonurture/design-system/dist/styles/output.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import applicationConfig from './applicationConfig';

Sentry.init({
  dsn: applicationConfig.sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: applicationConfig.environment,
  enabled: ['production', 'staging'].includes(applicationConfig.environment),
  // Tracing
  tracesSampleRate: 0.1, //  Capture 10% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  beforeSend: (event) => {
    event.extra = event.extra || {};
    event.extra.deployment = applicationConfig.deployment;

    event.tags = event.tags || {};
    event.tags.deployment = applicationConfig.deployment;
    event.tags.tier = 'frontend';

    return event;
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React>
  <App />,
  //</React>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
