import { DashboardHeader, useToast } from '@gonurture/design-system';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useErrorHandler } from 'hooks/';
import { refreshMembersList } from 'apis/';
import { useClassroom } from 'store/selectors';
import { setClassroomDetails } from 'store/reducers/classroom-reducer';
import { useDispatch } from 'react-redux';

export const NAVS = {
  dashboard: 'dashboard',
  tasks: 'tasks',
  classroomSettings: 'classroomSettings',
  support: 'support',
};

const TeacherHomeNav = ({ activeTab, onTabChange }) => {
  const [refreshMembersText, setRefreshMembersText] =
    useState('Refresh members');

  const { channelId } = useClassroom();
  const navigate = useNavigate();
  const errorHandler = useErrorHandler();
  const { toast } = useToast();
  const dispatch = useDispatch();

  const navigateToAssignmentTypePage = () => {
    navigate('assignment/type');
  };

  const handleTabClick = (tab) => {
    onTabChange(tab);
  };

  const primaryTabs = [
    {
      name: 'Dashboard',
      value: NAVS.dashboard,
      icon: 'grey-wand',
      activeIcon: 'colorful-wand',
      onClick: () => handleTabClick(NAVS.dashboard),
    },
    {
      name: 'Tasks',
      value: NAVS.tasks,
      icon: 'grey-check-in-circle-overlap',
      activeIcon: 'colorful-check-in-circle',
      onClick: () => handleTabClick(NAVS.tasks),
    },
  ];

  const secondaryAction = {
    name: 'New Task',
    onClick: navigateToAssignmentTypePage,
  };

  const handleMembersRefresh = async () => {
    try {
      toast({
        title: 'Refresh in progress',
        description: 'Refreshing...',
      });

      const members = await refreshMembersList(channelId);
      dispatch(setClassroomDetails({ members }));

      toast({
        description: 'Refresh complete!',
      });
    } catch (e) {
      errorHandler(e, () => {
        toast({
          description: 'Could not refresh members. Please try again.',
          variant: 'destructive',
        });
      });
      setRefreshMembersText('Refreshing...');
    }
  };

  const secondaryTabs = [
    {
      icon: 'grey-settings',
      name: 'Classroom Settings',
      onClick: () => handleTabClick(NAVS.classroomSettings),
      value: 'settings',
    },
    {
      icon: 'grey-question-mark-in-circle',
      name: 'Support',
      onClick: () => handleTabClick(NAVS.support),
      value: 'support',
    },
    {
      icon: 'grey-clock-rewind',
      name: refreshMembersText,
      onClick: handleMembersRefresh,
      value: 'refresh-members',
    },
  ];

  return (
    <DashboardHeader
      primaryTabs={primaryTabs}
      activeTab={activeTab}
      secondaryTabs={secondaryTabs}
      secondaryAction={secondaryAction}
    />
  );
};

TeacherHomeNav.defaultProps = {
  onTabChange: () => {},
};

TeacherHomeNav.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(NAVS)).isRequired,
  onTabChange: PropTypes.func,
};

export default TeacherHomeNav;
