import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAiFeedback, useCurrentAssignment } from 'store/selectors';
import { FeedbackFormSchema as FeedbackFormSchemaGenerator } from 'form-schemas/';
import {
  Input,
  NurtureIcon,
  Switch,
  TextArea,
  useToast,
} from '@gonurture/design-system';
import { useErrorHandler } from 'hooks/';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { assignmentShow, createFeedback, updateFeedback } from 'apis/';
import { addSubmissionFeedback } from 'store/reducers/user-assignments-reducer';
import RubricsGradingTable from '../rubrics-grading-table/RubricsGradingTable';
import { addAssignment } from 'store/reducers/assignments-reducer';
import PropTypes from 'prop-types';
import AttachmentsUploadAndDisplay from 'components/attachments-upload-and-display/AttachmentsUploadAndDisplay';
import {
  clearAiFeedbackTeacherActions,
  setAiFeedbackTeacherActions,
} from 'store/reducers/ai-feedback-teacher-actions-reducer';

const FeedbackForm = ({
  submission,
  defaultFeedback,
  assignment,
  userAssignmentId,
  canCancelEdit,
  onCancel,
}) => {
  const [feedbackCreateError, setFeedbackCreateError] = useState(false);
  const [feedbackCreateLoading, setFeedbackCreateLoading] = useState(false);
  const [feedback, setFeedback] = useState(defaultFeedback);
  const [feedbackAttachments, setFeedbackAttachments] = useState(
    defaultFeedback?.attachments || [],
  );
  const { assignmentId, channelId } = useParams();
  const currentAssignment = useCurrentAssignment(assignmentId);
  const {
    learningObjectives: aiLearningObjectives,
    grade: aiGrade,
    personalFeedback: aiPersonalFeedback,
  } = useAiFeedback();

  const [objectiveIds, setObjectiveIds] = useState([]);
  const [timerID, setTimerID] = useState();
  const FeedbackFormSchema = FeedbackFormSchemaGenerator(
    currentAssignment?.max_points,
  );
  const { toast } = useToast();
  const errorHandler = useErrorHandler();
  const dispatch = useDispatch();

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FeedbackFormSchema),
  });

  const handleCheckedChange = (checked, objectiveId, teacherAction = true) => {
    clearTimeout(timerID);
    let finalObjectives;

    if (checked) {
      setObjectiveIds((prev) => {
        const result = [...prev, objectiveId];
        setValue('objective_ids', result);
        finalObjectives = result;
        if (teacherAction) {
          dispatch(setAiFeedbackTeacherActions({ learningObjectives: result }));
        }
        return result;
      });
    } else {
      setObjectiveIds((prev) => {
        const result = prev.filter((id) => id !== objectiveId);
        setValue('objective_ids', result);
        finalObjectives = result;
        if (teacherAction) {
          dispatch(setAiFeedbackTeacherActions({ learningObjectives: result }));
        }
        return result;
      });
    }

    const timerId = setTimeout(() => {
      onSubmit({ objective_ids: finalObjectives });
    }, 1000); // wait 1 seconds before submitting in case they still want to check on uncheck
    setTimerID(timerId);
  };

  const handleAiLearningObjectiveChange = () => {
    const objectivesThatNeedsFeedback = aiLearningObjectives.map(
      (objectiveId) => parseInt(objectiveId),
    );
    const objectivesWithoutFeedback = currentAssignment?.objectives
      ?.filter((objective) => {
        return !objectivesThatNeedsFeedback.includes(objective.id);
      })
      .map((objective) => objective.id);

    for (const objectiveId of objectivesThatNeedsFeedback) {
      if (!objectiveIds.includes(objectiveId)) {
        handleCheckedChange(true, objectiveId, false);
      }
    }

    for (const objectiveId of objectivesWithoutFeedback) {
      if (objectiveIds.includes(objectiveId)) {
        handleCheckedChange(false, objectiveId, false);
      }
    }
  };

  const handleRubricsGradeChange = (result) => {
    clearTimeout(timerID);

    const timerId = setTimeout(() => {
      onSubmit(result);
    }, 1000);
    setTimerID(timerId);
  };

  const handlePointsChanged = (e) => {
    const points = e.target.value;
    setValue('points', points);
    dispatch(setAiFeedbackTeacherActions({ grade: points }));
    onSubmit({ points: points });
  };

  const handleCommentChanged = (e) => {
    const comment = e.target.value;
    setValue('comment', comment);
    dispatch(setAiFeedbackTeacherActions({ personalFeedback: comment }));
    onSubmit({ comment: comment });
  };

  const isChecked = (objectiveId) => objectiveIds.includes(objectiveId);

  const newFeedback = async () => {
    try {
      const payload = {
        submission_id: submission?.id,
        user_work_id: userAssignmentId,
      };
      setFeedbackCreateLoading(true);
      setFeedbackCreateError(false);
      const result = await createFeedback(channelId, payload);
      setFeedbackAttachments(() => result?.attachments);
      setFeedback(result);
      setFeedbackCreateLoading(false);
    } catch (e) {
      setFeedbackCreateLoading(false);
      setFeedbackCreateError(true);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when creating feedback',
          variant: 'destructive',
        });
      });
    }
  };

  const handleFeedbackUpload = async (attachment) => {
    setFeedbackAttachments((prev) => [...(prev || []), attachment]);
    if (submission) {
      dispatch(
        addSubmissionFeedback({
          userAssignmentId: userAssignmentId,
          submissionId: submission?.id,
          feedback: {
            ...feedback,
            attachments: [...(feedback?.attachments || []), attachment],
          },
        }),
      );
    }
  };

  const handleFeedbackAttachmentsDelete = (attachment) => {
    setFeedbackAttachments((prev) =>
      prev.filter((a) => a.id !== attachment.id),
    );

    toast({
      title: 'Success!',
      description: 'Attachment deleted successfully',
      variant: 'success',
    });

    if (submission) {
      dispatch(
        addSubmissionFeedback({
          userAssignmentId: userAssignmentId,
          submissionId: submission?.id,
          feedback: {
            ...feedback,
            attachments: feedback.attachments.filter(
              (a) => a.id !== attachment.id,
            ),
          },
        }),
      );
    }
  };
  const onSubmit = async (data) => {
    if (!feedback?.id) return;

    try {
      const payload = {
        ...data,
        submission_id: submission?.id,
        user_work_id: userAssignmentId,
      };
      // setFeedbackUpdateLoading(true);
      const result = await updateFeedback(channelId, feedback?.id, payload);
      if (submission) {
        dispatch(
          addSubmissionFeedback({
            userAssignmentId: userAssignmentId,
            submissionId: submission?.id,
            feedback: result,
          }),
        );
      }
      toast({ description: 'Feedback saved successfully' });
      refetchAssessment(); // dont wait for this, let it run in the background
    } catch (e) {
      // setFeedbackUpdateLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when creating feedback',
          variant: 'destructive',
        });
      });
    }
  };

  const refetchAssessment = async () => {
    const assignment = await assignmentShow(channelId, assignmentId);
    dispatch(addAssignment(assignment));
  };

  useEffect(() => {
    if (!defaultFeedback && userAssignmentId) newFeedback();
    dispatch(clearAiFeedbackTeacherActions());
  }, [userAssignmentId]);

  useEffect(() => {
    setValue('points', feedback?.points);
    setValue('comment', feedback?.comment);
    setValue('objective_ids', feedback?.objectives || []);
    setObjectiveIds(() => feedback?.objectives || []);
  }, [feedback]);

  useEffect(() => {
    if (aiLearningObjectives) handleAiLearningObjectiveChange();
  }, [aiLearningObjectives]);

  useEffect(() => {
    if (aiGrade) {
      setValue('points', aiGrade);
      onSubmit({ points: aiGrade });
    }
  }, [aiGrade]);

  useEffect(() => {
    if (aiPersonalFeedback) {
      setValue('comment', aiPersonalFeedback);
      onSubmit({ comment: aiPersonalFeedback });
    }
  }, [aiPersonalFeedback]);

  return (
    <>
      {feedbackCreateError && (
        <div>
          <p>Error occurred when creating feedback</p>
        </div>
      )}

      {!feedbackCreateError && feedbackCreateLoading && (
        <div>
          <p>Loading...</p>
        </div>
      )}

      {!feedbackCreateError && !feedbackCreateLoading && (
        <div>
          <div className='flex flex-wrap items-center justify-between mb-4'>
            <div className='font-merriweather flex-grow text-2xl text-[#101828]'>
              Personal Feedback
            </div>

            {canCancelEdit && (
              <div
                className='flex items-center border px-3 py-2 text-sm cursor-pointer rounded-lg hover:bg-gray-50 transition-all'
                onClick={onCancel}
              >
                Cancel
                <NurtureIcon
                  icon='x-close'
                  className='ml-1 w-[15px] h-[15px]'
                />
              </div>
            )}
          </div>

          {assignment?.grade_display !== 'grade_display_rubrics' && (
            <div className='mb-6'>
              <div className='font-semibold text-[#101828] text-lg mb-1'>
                Learning Outcomes student needs feedback on
              </div>
              <div className='text-[#475467] text-sm'>
                You can check multiple learning outcomes or none.
              </div>
              <div className='mt-4'>
                {assignment?.objectives?.map((objective) => {
                  return (
                    <div key={objective.id} className='mb-3'>
                      <Switch
                        className='data-[state=checked]:bg-[#7F56D9]'
                        label={objective.name}
                        checked={isChecked(objective.id)}
                        onCheckedChange={(e) =>
                          handleCheckedChange(e, objective.id)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {(assignment?.grade_display === 'grade_display_points' ||
            assignment?.grade_display === 'grade_display_percentage') && (
            <div className='mb-6'>
              <Input
                error={errors?.points?.message}
                type='number'
                label='Points'
                {...register('points')}
                max={assignment?.max_points}
                min={0}
                onBlur={handlePointsChanged}
              />
            </div>
          )}

          {assignment?.grade_display === 'grade_display_rubrics' && (
            <div className='mb-6'>
              <RubricsGradingTable
                assignment={assignment}
                feedback={feedback}
                onGraded={handleRubricsGradeChange}
              />
            </div>
          )}

          <div className='mb-6'>
            <TextArea
              error={errors?.comment?.message}
              label='Personal Feedback Comment'
              placeholder='Enter the students personal feedback here...'
              rows={6}
              {...register('comment')}
              onBlur={handleCommentChanged}
            />
          </div>

          <div className='mb-6'>
            <AttachmentsUploadAndDisplay
              attachments={feedbackAttachments}
              buttonView
              buttonViewClassName='bg-[#FFFFFF] hover:bg-[#FFFFFF] border hover:border border-[#D0D5DD] hover:border-[#D0D5DD] font-semibold text-sm text-[#344054] hover:text-[#344054] rounded-lg'
              buttonViewContent={
                <div className='flex items-center]'>
                  <NurtureIcon className='mr-2' icon='black-image-plus' /> Add
                  Attachments
                </div>
              }
              onAddAttachment={handleFeedbackUpload}
              attachableParams={{ feedback_id: feedback?.id }}
              onRemoveAttachment={handleFeedbackAttachmentsDelete}
            />
          </div>
        </div>
      )}
    </>
  );
};

FeedbackForm.defaultProps = {
  assignment: undefined,
  submission: undefined,
  defaultFeedback: undefined,
  userAssignmentId: undefined,
  canCancelEdit: false,
  onCancel: () => {},
};

FeedbackForm.propTypes = {
  assignment: PropTypes.object,
  submission: PropTypes.object,
  defaultFeedback: PropTypes.object,
  userAssignmentId: PropTypes.string,
  canCancelEdit: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default FeedbackForm;
