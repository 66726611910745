import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import applicationConfig from '../../applicationConfig';
import { configure } from 'apis/';

const Setup = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState('');
  const tenantId = searchParams.get('tenant_id');
  const channelId = searchParams.get('channel_id');
  const groupId = searchParams.get('group_id');

  const setUpTenant = async () => {
    try {
      // Create channel in backend
      const response = await configure({
        channel_id: channelId,
        group_id: groupId,
        tenant_id: tenantId,
      });

      await microsoftTeams.app.initialize();

      const contentUrl = `${applicationConfig.appBaseUrl}/teams/${tenantId}/channels/${response?.data?.id}`;
      microsoftTeams.pages.config.registerOnSaveHandler((saveEvent) => {
        microsoftTeams.pages.config.setConfig({
          websiteUrl: contentUrl,
          contentUrl: contentUrl,
          entityId: 'assignmentsTab',
          suggestedDisplayName: 'Nurture',
        });

        saveEvent.notifySuccess();
      });

      microsoftTeams.pages.config.setValidityState(true);
    } catch (error) {
      console.error(error);
      setError('Error occurred during setup:');
    }
  };

  useEffect(() => {
    setUpTenant();
  }, []);

  return (
    <div className='p-3'>
      <div className='text-xl font-semibold text-[#344054] mb-3'>
        Let's get started with assignments! 🙌
      </div>
      <p className='text-[#667085]'>
        Create and issue assignments in your channel. Collect submissions,
        review submissions, return personalised feedback and collect learning
        insights. Our research-backed framework ensures that the feedback a
        student receives aligns their individual learning goals.
      </p>
      {error && <p className='text-destructive'>{error}</p>}
    </div>
  );
};

export default Setup;
