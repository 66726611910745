import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';

export const useUser = () => useSelector((state) => state.user);

export const useClassroom = () =>
  useSelector((state) => {
    return {
      channelId: state.classroom.channelId,
      tenantId: state.classroom.tenantId,
      members: state.classroom.members,
      onboardingComplete: state.classroom.onboardingComplete,
      subject: state.classroom.subject,
      teacherRoles: state.classroom.teacherRoles,
      attachments: state.classroom.attachments,
      students: state.classroom.members.filter(
        (member) => member.role === 'student',
      ),
      teachers: state.classroom.members.filter(
        (member) => member.role === 'teacher',
      ),
    };
  });

export const useAppError = () => useSelector((state) => state.appError);

export const useCurrentAssignment = (id) =>
  useSelector((state) => state?.assignments[id]);

export const useNewAssignmentId = () =>
  useSelector((state) => {
    const keys = Object.keys(state?.assignments);

    const newAssignmentId = keys.find((key) => {
      return state?.assignments[key].new === true;
    });

    return newAssignmentId;
  });

export const useAssignments = () => {
  return useSelector((state) => {
    const nonDraftAssignments = Object.values(state?.assignments || {}).filter(
      (assignment) => assignment.status !== 'unsaved_draft',
    );
    return orderBy(nonDraftAssignments, 'created_at', 'desc');
  });
};

export const useCurrentUserAssignment = (id) =>
  useSelector((state) => state?.userAssignments[id]);

export const useUserAssignments = () =>
  useSelector((state) =>
    orderBy(Object.values(state?.userAssignments || {}), 'created_at', 'desc'),
  );

export const useAiAssignment = () => {
  return useSelector((state) => state?.aiAssignment);
};

export const useAiAssignmentPrompt = () => {
  return useSelector((state) => state?.aiAssignmentPrompt);
};

export const useAiFeedback = () => useSelector((state) => state?.aiFeedback);

export const useAiFeedbackTeacherActions = () => {
  return useSelector((state) => state?.aiFeedbackTeacherActions);
};

export const useAnalytics = () => useSelector((state) => state?.analytics);

export const useNonGradedRubricsObjectives = () => {
  return useSelector((state) => state?.nonGradedRubricsObjectives);
};

export const useAiMessage = (id) => {
  return useSelector((state) => state?.aiMessages[id]);
};
