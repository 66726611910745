import { Separator, NurtureIcon } from '@gonurture/design-system';
import LearningOutcomesFeedback from './LearningOutcomesFeedback';
import PersonalFeedbackComment from './PersonalFeedbackComment';
import StudentReflectionSummary from '../student-reflection/StudentReflectionSummary';
import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from 'store/selectors';
import DownloadUserAssignmentButton from 'components/exportable-feedback/DownloadUserAssignmentButton';

const FeedbackDetails = () => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const feedback = currentUserAssignment?.submissions?.[0]?.feedback || {};
  const reflection = feedback?.reflection || {};

  return (
    <div className='mt-10'>
      <div className='lg:flex lg:space-x-10 mt-7'>
        <div className='lg:w-1/2'>
          <div className='mb-8'>
            <LearningOutcomesFeedback showScrollableSummary={true} />
          </div>

          <div>
            <PersonalFeedbackComment asSummary={true} />
          </div>
        </div>

        <div className='lg:w-1/2'>
          <div className='border border-[#D6BBFB] rounded-lg p-3'>
            <div className='flex justify-between items-center'>
              <div className='flex items-center flex-grow-1'>
                <div className='w-7 h-7 bg-[#5FE9D0] rounded-full flex items-center justify-center shrink-0'>
                  <NurtureIcon icon='green-verified-check' />
                </div>
                <div className='text-[#344054] font-semibold text-xl ml-2'>
                  Reflection Complete
                </div>
              </div>

              <div className='shrink-0'>
                <DownloadUserAssignmentButton
                  userAssignment={currentUserAssignment}
                  assignment={currentUserAssignment?.work}
                />
              </div>
            </div>

            <Separator className='my-4' />

            <StudentReflectionSummary
              reflectionFeeling={reflection.emoji}
              reflectionComment={reflection.comment}
              reflectionType={currentUserAssignment?.work?.reflection_type}
              threeQuestionComment={
                reflection?.three_two_one_comment?.question_three
              }
              twoQuestionComment={
                reflection?.three_two_one_comment?.question_two
              }
              oneQuestionComment={
                reflection?.three_two_one_comment?.question_one
              }
              allowEdit={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackDetails;
