import { useAssignments, useClassroom } from 'store/selectors';
import { useToast } from '@gonurture/design-system';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'hooks/';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addAssignments } from 'store/reducers/assignments-reducer';
import { assignmentsList } from 'apis/';
import AssignmentListLoader from '../../shared/AssignmentListLoader';
import EmptyPage from 'components/empty-page/EmptyPage';
import ErrorPage from 'components/error-page/ErrorPage';
import AssignmentCard from './AssignmentCard';
import InfiniteScroll from 'react-infinite-scroll-component';

const AssignmentList = () => {
  const [assignmentsLoading, setAssignmentsLoading] = useState(false);
  const [assignmentsError, setAssignmentsError] = useState('');
  const [meta, setMeta] = useState({});
  const navigate = useNavigate();
  const { channelId } = useClassroom();
  const assignments = useAssignments();
  const { toast } = useToast();
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();
  const showAssignmentLoader = !assignments || assignments?.length <= 0;
  const canLoadMore = meta.current_page < meta.total_pages;
  const perPage = 20;

  const navigateToAssignmentTypePage = () => {
    navigate('assignment/type');
  };

  const fetchAssignments = async (page = 1) => {
    try {
      if (showAssignmentLoader) {
        setAssignmentsLoading(true);
        setAssignmentsError('');
      }
      const { works: assignments, meta } = await assignmentsList(channelId, {
        page,
        per_page: perPage,
      });
      setMeta(meta);
      dispatch(addAssignments(assignments));
      setAssignmentsLoading(false);
    } catch (e) {
      setAssignmentsLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching assignments',
          variant: 'error',
        });
        if (showAssignmentLoader) setAssignmentsError(e.message);
      });
    }
  };

  const fetchNextAssignments = () => {
    if (meta.current_page < meta.total_pages) {
      fetchAssignments(meta.current_page + 1);
    }
  };

  useEffect(() => {
    fetchAssignments();
  }, []);

  return (
    <>
      {assignmentsError && (
        <ErrorPage
          title='Error occurred when fetching assignments'
          subtitle={assignmentsError}
          tryAgainText='Retry'
          onTryAgain={fetchAssignments}
        />
      )}

      {!assignmentsError && assignmentsLoading && <AssignmentListLoader />}

      {!assignmentsError && !assignmentsLoading && assignments?.length > 0 && (
        <InfiniteScroll
          next={fetchNextAssignments}
          hasMore={canLoadMore}
          loader={<div className='text-center'>Loading...</div>}
          dataLength={assignments.length}
        >
          <div>
            {assignments.map((assignment) => (
              <div className='mb-6' key={assignment.id}>
                <AssignmentCard key={assignment.id} assignment={assignment} />
              </div>
            ))}
          </div>
        </InfiniteScroll>
      )}

      {!assignmentsError &&
        !assignmentsLoading &&
        assignments?.length === 0 && (
          <EmptyPage
            title='Looks like there are no assessments in this channel yet.'
            subtitle='Create a new assessment and assign it to your students.'
            tryAgainText='New Task'
            onTryAgain={navigateToAssignmentTypePage}
          />
        )}
    </>
  );
};

export default AssignmentList;
