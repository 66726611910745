import './App.css';
import AppRoutes from './AppRoutes';
import { Provider } from 'react-redux';
import store from 'store';
import { Toaster } from '@gonurture/design-system';
import AppError from 'pages/errors/app-error/AppError';
import * as Sentry from '@sentry/react';
import UnexpectedError from 'pages/errors/unexpected-error/UnexpectedError';

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<UnexpectedError />}>
      <Provider store={store}>
        <AppError>
          <Toaster className='z-[1006]' />
          <div className='md:container md:mx-auto'>
            <AppRoutes />
          </div>
        </AppError>
      </Provider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
