const applicationConfig = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  appBaseUrl: process.env.REACT_APP_APP_BASE_URL,
  microsoftTeams: {
    clientId: process.env.REACT_APP_MS_TEAMS_CLIENT_ID,
    authority: process.env.REACT_APP_MS_TEAMS_AUTHORITY,
  },
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  deployment: process.env.REACT_APP_DEPLOYMENT,
};

export default applicationConfig;
