import ErrorPage from 'components/error-page/ErrorPage';

const UnexpectedError = () => {
  return (
    <div className='h-screen flex justify-center items-center'>
      <div className='w-full'>
        <ErrorPage
          title='Unexpected Error Occurred'
          subtitle='The nurture team is already aware of this. Please try again later.'
        />
      </div>
    </div>
  );
};

export default UnexpectedError;
