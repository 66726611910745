import {
  Emoji,
  FeedbackStatusBadge,
  Tooltip,
  NurtureIcon,
  SubmissionStatusBadge,
  titleize,
} from '@gonurture/design-system';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';

const StudentsSubmissionsTable = ({
  assignment,
  onSortSubmissions,
  onSelectSubmission,
}) => {
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortColumn, setSortColumn] = useState('firstName');
  const [sortedSubmissions, setSortedSubmissions] = useState([]);

  const getSubmissionStatusText = (submission) => {
    let primaryText, secondaryText, tertiaryText;
    if (submission.reflected) {
      primaryText = 'Reflection Completed';
    }

    if (submission.submitted) {
      secondaryText = 'Submitted';
    } else {
      secondaryText = 'Not Submitted';
    }

    if (submission.submission_due) {
      tertiaryText = submission.submitted
        ? 'Late Submission'
        : 'Submission Overdue';
    }

    return `${primaryText ? primaryText + ' | ' : ''} ${secondaryText} ${
      tertiaryText ? ' | ' + tertiaryText : ''
    }`.trim();
  };

  const showGrade = assignment?.grade_display !== 'grade_display_ungraded';

  const Empty = () => (
    <div className='rounded-full bg-[#F2F4F7] flex justify-center items-center text-xs font-semibold w-[24px] h-[24px]'>
      ?
    </div>
  );

  /* eslint-disable */
  const SortIcon = ({ column, ...props }) => {
    if (column !== sortColumn) return null;

    return (
      <NurtureIcon
        icon={`grey-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`}
        {...props}
      />
    );
  };
  /* eslint-enable */

  const sort = (column) => {
    let newSortDirection;
    if (column === sortColumn) {
      newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      newSortDirection = 'asc';
    }

    setSortColumn(column);
    setSortDirection(newSortDirection);

    const newSortedSubmissions = orderBy(
      sortedSubmissions,
      [column],
      [newSortDirection],
    );

    setSortedSubmissions(() => newSortedSubmissions);
    onSortSubmissions(newSortedSubmissions);
  };

  useEffect(() => {
    const submissions =
      assignment?.user_works?.map((userWork) => ({
        ...userWork,
        firstName: userWork.user.display_name.split(' ')[0] || '-',
        lastName: userWork.user.display_name.split(' ')[1] || '-',
        submission_status_text: getSubmissionStatusText(userWork),
        feedback_status_text: titleize(
          userWork.feedback_status.replace('_', ' '),
        ),
        ...(showGrade ? { grade: userWork.average_grade } : {}),
      })) || [];

    const sortedResult = orderBy(submissions, ['firstName'], ['asc']);
    setSortedSubmissions(() => sortedResult);
    onSortSubmissions(sortedResult);
  }, [assignment]);

  return (
    <div className='rounded-lg border border-[#EAECF0] shadow-sm'>
      <div className='flex justify-between items-center px-3 py-6'>
        <div className='font-semibold text-[#101828] text-lg'>
          Submissions & Feedback
        </div>

        {/*<Button className='border border-[#D0D5DD] hover:border-[#D0D5DD] bg-white hover:bg-white'>*/}
        {/*  <span className='hidden sm:inline-flex text-sm text-[#344054] font-semibold'>*/}
        {/*    Export as .csv*/}
        {/*  </span>*/}
        {/*  <NurtureIcon icon='black-download' className='sm:ml-2' />*/}
        {/*</Button>*/}
      </div>

      <div className='overflow-x-auto'>
        <table className='min-w-full bg-white border'>
          <thead>
            <tr className='bg-[#F9FAFB] text-left border-b'>
              <th
                className='p-3 min-w-[120px] border-x text-sm text-[#475467] font-semibold cursor-pointer'
                onClick={() => sort('firstName')}
              >
                <div className='flex'>
                  First Name
                  <SortIcon column='firstName' className='ml-1' />
                </div>
              </th>
              <th
                className='p-3 min-w-[120px] border-x text-sm text-[#475467] font-semibold cursor-pointer'
                onClick={() => sort('lastName')}
              >
                <div className='flex'>
                  Last Name
                  <SortIcon column='lastName' className='ml-1' />
                </div>
              </th>
              <th className='p-3 min-w-[200px] sm:min-w-[150px] border-x text-sm text-[#475467] font-semibold'>
                Status
              </th>
              <th
                className='p-3 min-w-[100px] border-x text-sm text-[#475467] font-semibold cursor-pointer'
                onClick={() => sort('average_confidence')}
              >
                <div className='flex'>
                  Confidence
                  <SortIcon column='average_confidence' className='ml-1' />
                </div>
              </th>
              <th className='p-3 min-w-[150px] border-x text-sm text-[#475467] font-semibold'>
                Feedback
              </th>
              {showGrade && (
                <th
                  className='p-3 min-w-[100px] border-x text-sm text-[#475467] font-semibold cursor-pointer'
                  onClick={() => sort('average_grade')}
                >
                  <div className='flex'>
                    Grade
                    <SortIcon column='average_grade' className='mx-1' />
                  </div>
                </th>
              )}
              <th
                className='p-3 min-w-[100px] border-x text-sm text-[#475467] font-semibold cursor-pointer'
                onClick={() => sort('average_reflection')}
              >
                <div className='flex'>
                  Reflection
                  <SortIcon column='average_reflection' className='mx-1' />
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {sortedSubmissions.map((userWork) => (
              <tr
                key={userWork.id}
                className='cursor-pointer hover:bg-gray-50'
                onClick={() => onSelectSubmission(userWork)}
              >
                <td className='px-3 py-5 border-b border-[#EAECF0]'>
                  <div className='text-sm text-[#101828]'>
                    {userWork.user.display_name.split(' ')[0] || '-'}
                  </div>
                </td>

                <td className='px-3 py-5 border-b border-[#EAECF0]'>
                  <div className='text-sm text-[#101828]'>
                    {userWork.user.display_name.split(' ')[1] || '-'}
                  </div>
                </td>

                <td className='px-3 py-5 border-b border-[#EAECF0]'>
                  <div>
                    <SubmissionStatusBadge submission={userWork} />
                  </div>
                </td>

                <td className='px-3 py-5 border-b border-[#EAECF0]'>
                  <div>
                    {userWork.confidence_text && (
                      <Tooltip
                        trigger={
                          <Emoji
                            width='24'
                            height='24'
                            confidence={userWork.confidence_text}
                          />
                        }
                      >
                        {userWork.confidence_text}
                      </Tooltip>
                    )}

                    {!userWork.confidence_text && <Empty />}
                  </div>
                </td>

                <td className='px-3 py-5 border-b border-[#EAECF0]'>
                  <div>
                    {
                      <FeedbackStatusBadge
                        feedbackStatus={userWork.feedback_status}
                      />
                    }
                  </div>
                </td>

                {showGrade && (
                  <td className='px-3 py-5 border-b border-[#EAECF0] text-[#475467] text-sm'>
                    <div>
                      {userWork?.average_grade || '?'}/{assignment?.max_points}
                    </div>
                  </td>
                )}

                <td className='px-3 py-5 border-b border-[#EAECF0]'>
                  <div>
                    {userWork.reflection_emoji && (
                      <Emoji
                        confidence={userWork.reflection_emoji}
                        width='24'
                        height='24'
                      />
                    )}

                    {!userWork.reflection_emoji && <Empty />}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

StudentsSubmissionsTable.defaultProps = {
  onSelectSubmission: () => {},
  onSortSubmissions: () => {},
};

StudentsSubmissionsTable.propTypes = {
  assignment: PropTypes.object.isRequired,
  onSelectSubmission: PropTypes.func,
  onSortSubmissions: PropTypes.func,
};

export default StudentsSubmissionsTable;
