import User from '../../../../components/user/User';
import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from '../../../../store/selectors';
import { ReflectionSelector } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const ReflectionFeelingSelector = ({
  reflectionFeelingValue,
  onChangeReflectionFeeling,
}) => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const currentAssignment = currentUserAssignment?.work;

  return (
    <div className='mx-auto w-full md:w-3/4 xl:w-1/2 mt-10'>
      <div className='mb-3'>
        <User user={currentAssignment?.user} />
      </div>

      <div className='text-[#344054] mb-3'>
        How do you feel about your feedback?
      </div>

      <ReflectionSelector
        isVertical={true}
        value={reflectionFeelingValue}
        onSelect={onChangeReflectionFeeling}
      />
    </div>
  );
};

ReflectionFeelingSelector.defaultProps = {
  reflectionFeelingValue: undefined,
  onChangeReflectionFeeling: () => {},
};

ReflectionFeelingSelector.propTypes = {
  reflectionFeelingValue: PropTypes.number,
  onChangeReflectionFeeling: PropTypes.func,
};

export default ReflectionFeelingSelector;
