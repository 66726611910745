import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from 'store/selectors';
import User from 'components/user/User';
import Attachments from 'components/attachments/Attachments';
import { AutoLinker } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const PersonalFeedbackComment = ({ asSummary }) => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const currentAssignment = currentUserAssignment?.work;
  const feedback = currentUserAssignment?.submissions?.[0]?.feedback || {};

  const proseClasses = `
      prose-headings:font-semibold
      prose-ul:list-disc prose-ul:list-inside prose-ul:mb-2
      prose-ol:list-decimal prose-ol:list-inside prose-ol:mb-2
      prose-li:text-md prose-li:italic
      prose-p:font-semibold prose-p:italic prose-p:mb-2 prose-p:text-lg
  `;

  return (
    <div
      className={`${asSummary ? '' : 'mx-auto w-full md:w-3/4 xl:w-1/2 mt-8'}`}
    >
      <div className='mb-5'>
        <User user={currentAssignment?.user} />
      </div>

      <div className={`mb-6 ${proseClasses}`}>
        <AutoLinker content={feedback?.comment} />
      </div>

      {feedback?.attachments?.length > 0 && (
        <div className='mt-5'>
          <div className='mb-5'>
            <User user={currentAssignment?.user} />
          </div>

          <div className='mb-5 text-[#344054]'>
            Here are some more <strong>resources</strong> for you to check out:
          </div>

          <Attachments attachments={feedback?.attachments} />
        </div>
      )}
    </div>
  );
};

PersonalFeedbackComment.defaultProps = {
  asSummary: false,
};

PersonalFeedbackComment.propTypes = {
  asSummary: PropTypes.bool,
};

export default PersonalFeedbackComment;
