import { NurtureIcon, Separator } from '@gonurture/design-system';
import StudentSubmissionSummary from './StudentSubmissionSummary';
import PropTypes from 'prop-types';

const EditSubmissionPrompt = ({ submission, onEditSubmission }) => {
  return (
    <div className='border px-4 py-6 rounded-md border-[#D6BBFB]'>
      <div className='flex items-center'>
        <div className='w-7 h-7 bg-[#5FE9D0] rounded-full flex items-center justify-center shrink-0'>
          <NurtureIcon icon='green-verified-check' />
        </div>

        <div className='text-xl text-[#344054] font-semibold ml-2'>
          Submission Complete
        </div>
      </div>

      <Separator className='my-4' />

      <StudentSubmissionSummary
        confidence={submission?.confidence_level_emoji}
        personalComment={submission?.comment}
        attachments={submission?.attachments}
        allowEdit={false}
      />

      <div className='mt-4'>
        <div
          className='border border-[#D0D5DD] flex text-lg font-semibold text-[#344054] cursor-pointer justify-center items-center rounded-lg p-3'
          onClick={onEditSubmission}
        >
          <span>Edit Submission</span>
          <NurtureIcon className='ml-3' icon='black-pen-edit' />
        </div>
      </div>
    </div>
  );
};

EditSubmissionPrompt.defaultProps = {
  submission: {},
  onEditSubmission: () => {},
};

EditSubmissionPrompt.propTypes = {
  submission: PropTypes.object,
  onEditSubmission: PropTypes.func,
};

export default EditSubmissionPrompt;
