import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from 'store/selectors';
import LearningOutcomesFeedback from '../feedback-details/LearningOutcomesFeedback';
import PersonalFeedbackComment from '../feedback-details/PersonalFeedbackComment';
import ReflectionFeelingSelector from './ReflectionFeelingSelector';
import StudentReflectionComment from './StudentReflectionComment';
import StudentReflectionSummary from './StudentReflectionSummary';
import StudentReflectionHeader from './StudentReflectionHeader';
import {
  StepProgress,
  Button,
  NurtureIcon,
  useToast,
} from '@gonurture/design-system';
import { createReflection, userAssignmentShow } from 'apis/';
import { useErrorHandler } from 'hooks/';
import { updateUserAssignment } from 'store/reducers/user-assignments-reducer';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

const StudentReflection = ({ onDone }) => {
  const [activeState, setActiveState] = useState('learningOutcomes');
  const [reflectionFeeling, setReflectionFeeling] = useState(null);
  const [reflectionComment, setReflectionComment] = useState(null);

  const [threeQuestionComment, setThreeQuestionComment] = useState(null);
  const [twoQuestionComment, setTwoQuestionComment] = useState(null);
  const [oneQuestionComment, setOneQuestionComment] = useState(null);

  const [reflectionCreateLoading, setReflectionCreateLoading] = useState(false);

  const contentRef = useRef(null);

  const { toast } = useToast();
  const errorHandler = useErrorHandler();

  const dispatch = useDispatch();

  const { channelId, userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const assessment = currentUserAssignment?.work;
  const reflectionType = assessment?.reflection_type;
  const feedback = currentUserAssignment?.submissions?.[0]?.feedback || {};

  const nextButtonText =
    activeState === 'reflectionComment' ? 'Finish' : 'Next';
  const showNextButton = activeState !== 'summary';

  const STATES = {
    learningOutcomes: {
      text: 'Learning Outcomes',
      position: 1,
      icon: 'purple-message',
      canGoNext: true,
      render: () => <LearningOutcomesFeedback showScrollableSummary={false} />,
    },
    personalFeedback: {
      text: 'Personal Feedback',
      position: 2,
      icon: 'purple-message',
      canGoNext: true,
      render: () => <PersonalFeedbackComment />,
    },
    feedbackReflection: {
      text: 'Feedback Reflection',
      position: 3,
      icon: 'purple-smiley-face',
      canGoNext: !!reflectionFeeling,
      render: () => (
        <ReflectionFeelingSelector
          reflectionFeelingValue={reflectionFeeling}
          onChangeReflectionFeeling={(v) => setReflectionFeeling(v)}
        />
      ),
    },
    reflectionComment: {
      text: 'Personal Comment',
      position: 4,
      icon: 'purple-message',
      canGoNext:
        reflectionType !== 'reflection_type_three_two_one' ||
        (!!threeQuestionComment &&
          !!twoQuestionComment &&
          !!oneQuestionComment),
      render: () => (
        <StudentReflectionComment
          reflectionCommentValue={reflectionComment}
          threeValue={threeQuestionComment}
          twoValue={twoQuestionComment}
          oneValue={oneQuestionComment}
          reflectionType={reflectionType}
          onChangeReflectionComment={(v) =>
            setReflectionComment(v.currentTarget.value)
          }
          onChangeThreeQuestionComment={(v) =>
            setThreeQuestionComment(v.currentTarget.value)
          }
          onChangeTwoQuestionComment={(v) =>
            setTwoQuestionComment(v.currentTarget.value)
          }
          onChangeOneQuestionComment={(v) =>
            setOneQuestionComment(v.currentTarget.value)
          }
        />
      ),
    },
    summary: {
      text: 'Summary',
      position: 5,
      render: () => (
        <StudentReflectionSummary
          reflectionFeeling={reflectionFeeling}
          reflectionComment={reflectionComment}
          threeQuestionComment={threeQuestionComment}
          twoQuestionComment={twoQuestionComment}
          oneQuestionComment={oneQuestionComment}
          reflectionType={reflectionType}
          allowEdit={true}
          onTransition={(state) => setActiveState(state)}
        />
      ),
    },
  };

  const maxSteps = Object.keys(STATES).length;

  const activeStateConfig = STATES[activeState];

  const prevState = () => {
    const currentStatePosition = activeStateConfig.position;
    const prevStatePosition = currentStatePosition - 1;

    if (prevStatePosition > 0) {
      const prevStateKey = Object.keys(STATES).find(
        (key) => STATES[key].position === prevStatePosition,
      );
      setActiveState(prevStateKey);
    }
  };
  const nextState = () => {
    const currentStatePosition = activeStateConfig.position;
    const nextStatePosition = currentStatePosition + 1;

    if (nextStatePosition <= maxSteps) {
      const nextStateKey = Object.keys(STATES).find(
        (key) => STATES[key].position === nextStatePosition,
      );
      setActiveState(nextStateKey);
    }
  };

  const onSubmit = async () => {
    try {
      setReflectionCreateLoading(true);
      const payload = {
        feedback_id: feedback.id,
        emoji: reflectionFeeling,
      };

      if (reflectionType === 'reflection_type_three_two_one') {
        payload.three_two_one_comment = {
          question_three: threeQuestionComment,
          question_two: twoQuestionComment,
          question_one: oneQuestionComment,
        };
      } else {
        payload.comment = reflectionComment;
      }

      await createReflection(channelId, payload);
      const assignment = await userAssignmentShow(channelId, userAssignmentId);
      dispatch(updateUserAssignment(assignment));
      setReflectionCreateLoading(false);
      onDone();
    } catch (e) {
      errorHandler(e, () => {
        setReflectionCreateLoading(false);
        toast({
          description: 'Error occurred when saving reflection',
          variant: 'destructive',
        });
      });
    }
  };

  useEffect(() => {
    createReflection(channelId, { feedback_id: feedback.id });
  }, []);

  useEffect(() => {
    const element = contentRef.current;
    element.scrollTop = 0;
  }, [activeState]);

  return (
    <div className=''>
      <div className='flex flex-col h-screen'>
        <div className='mx-auto w-full md:w-3/4 xl:w-1/2 mt-10'>
          <StudentReflectionHeader
            activeState={activeState}
            onPrev={prevState}
            activeStateConfig={activeStateConfig}
            assessmentName={assessment?.title}
          />

          {activeState !== 'summary' && (
            <div className='mt-6'>
              <StepProgress
                currentStep={activeStateConfig.position}
                steps={maxSteps - 1}
              />
            </div>
          )}
        </div>

        <div className='flex-grow overflow-scroll' ref={contentRef}>
          {activeStateConfig.render()}
        </div>

        {showNextButton && (
          <div className='my-8 mx-auto w-full md:w-3/4 xl:w-1/2'>
            <Button
              disabled={!activeStateConfig.canGoNext}
              className='w-full bg-[#7F56D9] hover:bg-[#7F56D9] text-white text-lg'
              onClick={nextState}
            >
              {nextButtonText}
              <NurtureIcon icon='white-arrow-right' className='ml-2' />
            </Button>
          </div>
        )}

        {activeState === 'summary' && (
          <div className='mx-auto w-full md:w-3/4 xl:w-1/2 my-8'>
            <div className='border border-[#D6BBFB] bg-[#F9F5FF] rounded-lg py-4 px-2'>
              <div className='text-center text-[#344054] mb-4'>
                You can’t make edits later once you unlock your grade. <br />
                Do you understand?
              </div>

              <Button
                className='border-[#3CCB7F] py-6 bg-[#3CCB7F] !text-white hover:border-[#3CCB7F] hover:bg-[#3CCB7F] font-semibold text-lg w-full'
                withLoader={true}
                loading={reflectionCreateLoading}
                onClick={onSubmit}
              >
                Yes, Unlock my grade{' '}
                <NurtureIcon icon='white-arrow-right' className='ml-2' />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

StudentReflection.defaultProps = {
  onDone: () => {},
};

StudentReflection.propTypes = {
  onDone: PropTypes.func,
};

export default StudentReflection;
