import { createSlice } from '@reduxjs/toolkit';

export const aiAssignmentPromptSlice = createSlice({
  name: 'aiAssignmentPrompt',
  initialState: null,
  reducers: {
    setAiAssignmentPrompt: (state, action) => {
      state = action.payload;
      return state;
    },
    clearAiAssignmentPrompt: (state) => {
      state = null;
      return state;
    },
  },
});

export const { setAiAssignmentPrompt, clearAiAssignmentPrompt } =
  aiAssignmentPromptSlice.actions;

export default aiAssignmentPromptSlice.reducer;
