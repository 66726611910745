import AssignmentForm from 'components/assignment-form/AssignmentForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useClassroom } from 'store/selectors';
import AssignmentTypeHeader from 'components/assignment-type-header/AssignmentTypeHeader';
import HeaderWithBackButton from 'components/header-with-back-button/HeaderWithBackButton';
import AssessmentAiChat from 'components/ai-chat/AssessmentAiChat';
import { ScrollArea } from '@gonurture/design-system';
import { useState } from 'react';

const AssignmentNew = () => {
  const [assignmentId, setAssignmentId] = useState(null);
  const { assignmentType } = useParams();
  const { channelId, tenantId } = useClassroom();
  const navigate = useNavigate();

  const handleBack = () =>
    navigate(
      `/teams/${tenantId}/channels/${channelId}/assignment/assistant/${assignmentType}`,
    );

  return (
    <div className='flex flex-col sm:flex-row'>
      {/* Left Column - Main Content */}
      <div className='w-full order-2 sm:order-1 sm:w-1/2 md:w-7/12 lg:w-8/12 p-6 sm:border-r sm:h-screen sm:overflow-y-scroll px-2'>
        <HeaderWithBackButton
          header={<AssignmentTypeHeader assignmentType={assignmentType} />}
          onBack={handleBack}
        />

        <div className=''>
          <AssignmentForm onCreateNewAssignment={setAssignmentId} />
        </div>
      </div>

      {/* Right Column - AI Chat */}
      <div className='w-full h-screen overflow-y-scroll order-1 sm:order-2 sm:w-1/2 md:w-5/12 lg:w-4/12 sm:pl-0'>
        <AssessmentAiChat assignmentId={assignmentId} />
      </div>
    </div>
  );
};

export default AssignmentNew;
